import React, { useState, useEffect } from 'react';
import {
  IconButton,
  Avatar,
  Box,
  CloseButton,
  Flex, Stack, Table, Thead, Tbody, Td, Tr, Th, Grid, GridItem,
  HStack,
  VStack,
  Icon,
  useColorModeValue,
  Link,
  Drawer,
  DrawerContent,
  Text,
  useDisclosure,
  BoxProps,
  FlexProps,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Divider,
} from '@chakra-ui/react';
import {
  FiHome,
  FiTrendingUp,
  FiCompass,
  FiStar,
  FiSettings,
  FiMenu,
  FiBell,
  FiChevronDown,
} from 'react-icons/fi';

function DashboardComponent(props) {

  //const [RemainStockList, setRemainStockList] = useState([]);

  useEffect(() => {
    //LoadRemainingStock();
  }, [])


//   const LoadRemainingStock = () => {
//     //debugger;
//     StockService.GetRemainingStockCount().then(data => {
//       if (data.data.IsSuccess) {
//         setRemainStockList(data.data.Data);
//       } else {
//         setRemainStockList([])
//       }
//     }).catch(error => {
//       setRemainStockList([])
//     })
//   }

  return (
    <>
      <h1>Dashboard</h1>

      <br /><br /><br />
      <Grid >

        {/* <GridItem w='50%' >
          {RemainStockList.length > 0 ? <>
            <Divider />
            <Table variant='striped' size='sm'>
              <Thead>
                <Tr >
                  <Th textAlign='center' colSpan={2}><b>Remaing Stock Count</b></Th>
                </Tr>
              </Thead>
              <Thead>
                <Tr>
                  <Th>Product Name</Th>
                  <Th>Count</Th>
                </Tr>
              </Thead>
              <Tbody>
                {/* {RemainStockList.map((data, index) => {
                  return (<Tr key={index}>
                    <Td>{data.Product_Name}</Td>
                    <Td>{parseInt(data.Product_Count) >= 51 ? data.Product_Count : <span className='Free'> {data.Product_Count}</span>}</Td>
                  </Tr>)
                })} */}
              {/* </Tbody>
            </Table></> : <></>}
        </GridItem> */} 
      </Grid>
    </>
  )
}

export default DashboardComponent

