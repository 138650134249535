import React, { useEffect, useState, useRef } from 'react'
import { useForm } from 'react-hook-form'
import * as VideoService from '../../Services/Video/VideoService'
import toast from 'react-hot-toast';
import ContainerComponent from '../Container/ContainerComponent';
import { VStack, FormControl,HStack, Tooltip,FormLabel, FormErrorMessage, Input, Checkbox, Modal, ModalOverlay, ModalContent, ModalBody, ModalHeader, ModalCloseButton, ModalFooter, useDisclosure, Flex, Stack, Heading, Table, ButtonGroup, Tfoot, Box, TableContainer, TableCaption, Spacer, Button, Thead, Tbody, Td, Tr, Th, Divider } from '@chakra-ui/react'
import { FaCopyright, FaPlus } from "react-icons/fa";
import ModalComponent from '../Modal/ModalComponent';
import { FiLayout } from 'react-icons/fi';
import { AddIcon, HamburgerIcon, EditIcon, DeleteIcon } from '@chakra-ui/icons';
import * as CommonVariables from '../../Common/CommonVariables';
import * as AttachmentService from "../../Services/Attachment/AttachmentService";
import FilePicker from "chakra-ui-file-picker";
import { Textarea } from 'evergreen-ui';
import JoditEditor from "jodit-react";


function VideoComponent() {
    const { register, setValue, reset, setError, handleSubmit, formState: { errors } } = useForm({ mode: "onBlur" });
    const { isOpen: isAddOpen, onOpen: onAddOpen, onClose: onAddClose } = useDisclosure();
    const [videolist, setVideoMasterList] = useState([]);
    const [selectedvideoid, SetSelectedVideo] = useState(0);    
    const [page, setPage] = useState(1);
    const [videoImage, setVideoImage] = useState('');
    const [Image, setImage] = useState('');
    const descriptioneditor = useRef('');
    const [detaildescription, setDetailDescription] = useState('');
    const shortdescrip_config = { //Short_DetailDescription
        readonly: false, // all options from https://xdsoft.net/jodit/doc/
        height: 300
    }

    useEffect(() => {
          LoadVideoMasterList();
    }, [])

       
    const LoadVideoMasterList = () => {
        VideoService.GetVideoMasterList().then(data => {
            if (data.data.IsSuccess) {
                debugger;
                setVideoMasterList(data.data.Data);
            } else {
                toast.error("Somthing went wrong");
            }
        }).catch(error => {
            toast.error("Somthing went wrong");
        })
    }

 
    const AddVideo = () => {
        reset({});    
        reset();    
        SetSelectedVideo(0);
        onAddOpen();
        setVideoImage('');
        setImage('');
        setDetailDescription('');

        
    }
    const validateVideo = (data) => {
        if( videoImage == '' || Image ==''){
            toast.error("Please upload one image.");
            return false;
          }
          return true;
    }
 
    const SaveVideoDetail = (value) => {
        debugger;
        if (descriptioneditor.current.value == "" || descriptioneditor.current.value == null) {
            toast.error('Video Description is mandatory!')
            return false;
        }
        else{
        if(validateVideo(value))
        {    
            value.Description = descriptioneditor.current.value;   
        if (selectedvideoid <= 0 ) {
            value.ImageUrl =Image;
            value.VideoUrl =videoImage;
            VideoService.SaveVideoDetails(value).then(data => {
                if (data.data.IsSuccess) {
                    toast.success("Success.");
                    onAddClose();
                    LoadVideoMasterList();
                } else {
                    toast.error("Somthing went wrong.");
                }
            }).catch(error => {
                toast.error("Somthing went wrong.");
            })
        } else {
            value.Id = selectedvideoid;
            value.ImageUrl =Image;
            value.VideoUrl =videoImage;
            VideoService.UpdateVideoDetails(value).then(data => {
                if (data.data.IsSuccess) {
                    toast.success("Success");
                    onAddClose();
                    LoadVideoMasterList();
                } else {
                    toast.error("Somthing went wrong.");
                }
            }).catch(error => {
                toast.error("Somthing went wrong.");
            })
        }
    }
    else{
        return false;
    }
}
    }

    const EditVideoMaster = (id) => {
        SetSelectedVideo(id);
        reset({});
        VideoService.GetVideoDetailsById(id).then(data => {
            if (data.data.IsSuccess) {
                reset(data.data.Data);
                setVideoImage(data.data.Data.VideoUrl);
                setImage(data.data.Data.ImageUrl);
                setDetailDescription(data.data.Data.Description);
                onAddOpen();
            } else {
                toast.error("Somthing went wrong");
            }
        }).catch(error => {
            toast.error("Somthing went wrong");
        })
    }

    const DeleteVideo = (id) => {
        debugger;
        var action = window.confirm('Do you want to delete Data?');
        if (action == true) {
            VideoService.DeleteVideoById(id).then(data => {
                if (data.data.IsSuccess) {
                    console.log(data.data)
                    toast.success(data.data.ResponseMessage);
                    LoadVideoMasterList();
                }
            }).catch(error => {

            })
        }
    }

    const VideoImageFileUploadToS3 = (fileList) => {
        debugger;
        if (fileList != null && fileList.length > 0) {
          var formdata = new FormData();
          formdata.append("Myfile", fileList[0]);
    
          AttachmentService.FileUploadToS3(formdata)
            .then((response) => {
              if (response.data.IsSuccess) {
                toast.success(response.data.ResponseMessage);
                setVideoImage(response.data.Data);
              } else {
                setVideoImage("");
                toast.error(response.data.ResponseMessage);
              }
            })
            .catch((error) => {
              toast.error("Something went wrong!");
            });
        } else {
          toast.error("Please choose Image First!");
          setVideoImage("");
        }
      };
      const ImageFileUploadToS3 = (fileList) => {
        debugger;
        if (fileList != null && fileList.length > 0) {
          var formdata = new FormData();
          formdata.append("Myfile", fileList[0]);
    
          AttachmentService.FileUploadToS3(formdata)
            .then((response) => {
              if (response.data.IsSuccess) {
                toast.success(response.data.ResponseMessage);
                setImage(response.data.Data);
              } else {
                setImage("");
                toast.error(response.data.ResponseMessage);
              }
            })
            .catch((error) => {
              toast.error("Something went wrong!");
            });
        } else {
          toast.error("Please choose Image First!");
          setImage("");
        }
      };

    return (




        <ContainerComponent Title="Video Master List" Icon={<FiLayout />} ShowDownloadAction="false" ShowAction="true" BtnIcon={<FaPlus />} ButtonTitle="Video" AddNewAction={AddVideo}>
            <TableContainer>
                <Table variant='simple'>
                    <Thead>
                        <Tr>
                        <Th>Id</Th>
                            <Th>Title</Th>
                            <Th>Image Url</Th> 
                            <Th>Video Url</Th>         
                            <Th>Is Active</Th>                          
                            <Th>Action</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {videolist.map((data, index) => {
                            return (<Tr key={index}>
                                <Td>{data.Id}</Td>
                                <Td>{data.Title}</Td>
                                <Td><a target='_blank' href={data.ImageUrl}><img height={"40px"} width={"40px"} src={data.ImageUrl}></img></a></Td>
                                <Td><a target='_blank' href={data.VideoUrl}><img height={"40px"} width={"40px"} src={data.ImageUrl}></img></a></Td>
                        
                                                           
                            
                                <Td>
                                    <Checkbox colorScheme={data.Is_Active ? 'green' : 'red'} defaultChecked isReadOnly>
                                    </Checkbox>
                                </Td> 
                                <Td>
                                <ButtonGroup variant={"outline"} spacing="1">
                <Tooltip label='Edit Video' placement='auto-start'>
                    <Button colorScheme="blue" variant="solid" size={"xs"} onClick={() => EditVideoMaster(data.Id)} >
                        <EditIcon />
                    </Button >
                </Tooltip>
                <Tooltip label='Delete Video' placement='auto-start'>
                    <Button colorScheme="red" variant="solid" size={"xs"} onClick={() => DeleteVideo(data.Id)}>
                        <DeleteIcon />
                    </Button>
                </Tooltip>
              
            </ButtonGroup >
                                </Td>
                            </Tr>)
                        })}
                    </Tbody>
                    {videolist.length <= 0 ? (
                        <Tfoot>
                            <Tr colSpan="3">
                                <Td>
                                    No Data Available
                                </Td>
                            </Tr>
                        </Tfoot>
                    ) : <></>
                    }

                </Table>
            </TableContainer>
          
            <ModalComponent size="2xl" Title="Add/Update Video" isOpen={isAddOpen} onOpen={onAddOpen} onClose={onAddClose} onSave={handleSubmit(SaveVideoDetail)} >
                <Stack spacing={4}>
                    <HStack>
                        <Box width={"full"}>
                            <FormControl isRequired isInvalid={errors.Title}>
                                <FormLabel>Enter Title</FormLabel>
                                <Input placeholder='Enter Title' {...register("Title", { required: "Field is Mandatory" })} />
                                <FormErrorMessage>
                                    {errors.Title && errors.Title.message}
                                </FormErrorMessage>
                            </FormControl>
                        </Box>
                        <Box width={"full"}>
                            <FormControl isRequired isInvalid={errors.SequenceNo}>
                                <FormLabel>Enter SequenceNo</FormLabel>
                                <Input placeholder='Enter SequenceNo' {...register("SequenceNo", { required: "Field is Mandatory" })} />
                                <FormErrorMessage>
                                    {errors.SequenceNo && errors.SequenceNo.message}
                                </FormErrorMessage>
                            </FormControl>
                        </Box>
                        </HStack>
                        <HStack>
                        <Box width={"full"}>
                            <FormControl isRequired >
                                <FormLabel>Description</FormLabel>
                                <JoditEditor
                                    ref={descriptioneditor}
                                    value={detaildescription}
                                    config={shortdescrip_config}
                                    tabIndex={1} // tabIndex of textarea
                                    onBlur={newDetailContent => setDetailDescription(newDetailContent)} // preferred to use only this option to update the content for performance reasons
                                    onChange={newDetailContent => { }}
                                />
                            </FormControl>
                        </Box>
                        </HStack>
                      <HStack>
                      <Box width={"full"}>                          
                               
                               <FormControl>
                                   <FormLabel>Is Youtube Video</FormLabel>
                                   <Checkbox  colorScheme='green'  {...register("IsYoutubeVideo")}>
                                   </Checkbox>
                                   <FormErrorMessage>
                                   </FormErrorMessage>
                               </FormControl>
                        </Box>
                      </HStack>
                        
                      <HStack>
                        <Box width={"full"}>
              <FormControl
                isRequired={false}
                isInvalid={errors.PopUp_Image}>
                <FormLabel>Video Image Url</FormLabel>

                <FilePicker
                  onFileChange={(fileList) => {
                    VideoImageFileUploadToS3(fileList);
                  }}
                  placeholder="Video Image"
                  clearButtonLabel="Clear"
                  multipleFiles={false}
                  accept="application/png"
                  hideClearButton={false}
                />
                <FormErrorMessage>
                  {errors.videoImage && errors.videoImage.message}
                </FormErrorMessage>
                <Box width={"50%"}>
            <a href={videoImage} target="blank"><img src={videoImage} width='50px' height='50px' /></a>
           </Box>
              </FormControl>
            
           </Box>
</HStack>
<HStack>
              <FormControl
                isRequired={false}
                isInvalid={errors.PopUp_Image}>
                <FormLabel>Image Url</FormLabel>

                <FilePicker
                  onFileChange={(fileList) => {
                    ImageFileUploadToS3(fileList);
                  }}
                  placeholder="Image"
                  clearButtonLabel="Clear"
                  multipleFiles={false}
                  accept="application/png"
                  hideClearButton={false}
                />
                <FormErrorMessage>
                  {errors.Image && errors.Image.message}
                </FormErrorMessage>
                <Box width={"50%"}>
            <a href={Image} target="blank"><img src={Image} width='50px' height='50px' /></a>
            </Box>
              </FormControl>
           
           
          

                       
                    </HStack>                                 
                    <HStack>
                        <Box width={"full"}>
                            {
                                selectedvideoid > 0 ? (
                                    <FormControl>
                                        <FormLabel>Is Active</FormLabel>
                                        <Checkbox colorScheme='green'  {...register("Is_Active",)}>
                                        </Checkbox>
                                        <FormErrorMessage>
                                        </FormErrorMessage>
                                    </FormControl>
                                ) : <></>
                            }
                        </Box>
                    </HStack>
                
                   
                </Stack>
            </ModalComponent>
          
           
        </ContainerComponent >
    )
}

export default VideoComponent