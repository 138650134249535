import axios from 'axios'
import * as CommonVariables from '../../Common/CommonVariables'
import { trackPromise } from 'react-promise-tracker';                       


//for bhk list
export const GetBannerMasterList = async () => {
    var result = await trackPromise(axios.get(CommonVariables.API_DOMAIN + `BannerMaster/GetBannerList`, CommonVariables.API_HEADER)).then(response => response);
    return result;
}

//for detail by id
export const GetBannerListById = async (id) => {
    var result = await trackPromise(axios.get(CommonVariables.API_DOMAIN + `BannerMaster/GetBannerListById?bannerid=${id}`, CommonVariables.API_HEADER)).then(response => response);
    return result;
}

//for saving new bhk detail
export const SaveBannerDetails = async (data) => {
 
    var result = await trackPromise(axios.post(CommonVariables.API_DOMAIN + `BannerMaster/SaveBannerDetails`,JSON.stringify(data), CommonVariables.API_HEADER)).then(response => response);
    return result;
}

//for updating existing bhk detail
export const UpdateBannerDetails = async (data) => {
    var result = await trackPromise(axios.post(CommonVariables.API_DOMAIN + `BannerMaster/UpdateBannerDetails`,JSON.stringify(data), CommonVariables.API_HEADER)).then(response => response);
    return result;
}


export const DeleteBannerById = async (Id) => {
    //debugger;
    const response = await trackPromise(axios.post(CommonVariables.API_DOMAIN + `BannerMaster/DeleteBannerById?bannerid=${Id}`, CommonVariables.API_HEADER))
        .then(response => response);
    return response;
}


