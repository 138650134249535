import axios from 'axios'
import * as CommonVariables from '../../Common/CommonVariables'
import { trackPromise } from 'react-promise-tracker';                       


//for bhk list
export const GetServiceFAQMasterList = async () => {
    var result = await trackPromise(axios.get(CommonVariables.API_DOMAIN + `ServiceFAQMaster/GetServiceFAQMasterList`, CommonVariables.API_HEADER)).then(response => response);
    return result;
}

//for detail by id
export const GetServiceFAQListById = async (id) => {
    var result = await trackPromise(axios.get(CommonVariables.API_DOMAIN + `ServiceFAQMaster/GetServiceFAQListById?serviceid=${id}`, CommonVariables.API_HEADER)).then(response => response);
    return result;
}

//for saving new bhk detail
export const SaveServiceFAQDetails = async (data) => {
 
    var result = await trackPromise(axios.post(CommonVariables.API_DOMAIN + `ServiceFAQMaster/SaveServiceFAQDetails`,JSON.stringify(data), CommonVariables.API_HEADER)).then(response => response);
    return result;
}

//for updating existing bhk detail
export const UpdateServiceFAQDetails = async (data) => {
    var result = await trackPromise(axios.post(CommonVariables.API_DOMAIN + `ServiceFAQMaster/UpdateServiceFAQDetails`,JSON.stringify(data), CommonVariables.API_HEADER)).then(response => response);
    return result;
}


export const DeleteServiceFAQById = async (Id) => {
    //debugger;
    const response = await trackPromise(axios.post(CommonVariables.API_DOMAIN + `ServiceFAQMaster/DeleteServiceFAQById?serviceid=${Id}`, CommonVariables.API_HEADER))
        .then(response => response);
    return response;
}


