import React, { useEffect,useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom';
import * as LoginService from '../../Services/Login/LoginService'
import {
    Box,
    Flex,
    Stack,
    Heading,
    Text,
    Container,
    Input,
    Button,
    SimpleGrid,
    Avatar,
    AvatarGroup,
    useBreakpointValue,
    IconProps,
    Icon,
    FormLabel,
    FormControl,
    Checkbox,
    Image
} from '@chakra-ui/react';
import { thunk_set_user } from '../../Action/action'
import useLoaderState from '../../Common/useLoaderState';
import toast, { Toaster } from 'react-hot-toast';
import { connect } from 'react-redux';
import { store } from '../../Store/store';

const userInfo = store.getState();

function LoginComponent(props) {
    const { register, handleSubmit, formState: { errors } } = useForm({ mode: "onChange" });
    const [IsLoading] = useLoaderState();
    const history = useNavigate();
    const [showotpscreen, setshowotpscreen] = React.useState(false);
    const [otp, setOtp] = useState("");
    const [mobileno, setMobileno ] = useState("");
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);
    const sendOTP = () => {
      setMinutes(2);
      setSeconds(59);
    };
    
    const formSubmit = (value) => {
        debugger;
        LoginService.ValidateUser(value.UserName, value.Password).then(data => {
          debugger;
            if (data.data.ResponseMessage == "User Validated") {
                //debugger;
                setMobileno(data.data.Data.User.Mobile_No);
                props.dispatch(thunk_set_user(data.data.Data));
                toast.success("Login Success");
                setTimeout(() => {
                    history('/dashboard');
                }, 1000);
            }
            else if(data.data.ResponseMessage =="Validate OTP")
            { 
              setMobileno(data.data.Data.User.Mobile_No);
              //props.dispatch(thunk_set_user(data.data.Data));
              setshowotpscreen(true);
            }
            
            else {
                toast.error("Invalid User");
            }
        }).catch(error => {
            toast.error("Somthing went wrong. Please try again.");
        })
    }
    const resendOTP = () => {
      LoginService.ReSendUserOTP(mobileno).then(data => {
        if (data.data.ResponseMessage == "OTP Sent") {
          toast.success("OTP Sent");
          setMinutes(1);
          setSeconds(59);
        }
      })
     
    };
    const OTPformSubmit = (value) => {
      debugger;
      LoginService.ValidateUserOTP(value.Login_OTP, mobileno).then(data => {
        if (data.data.ResponseMessage == "User Validated") {
            //debugger;
            props.dispatch(thunk_set_user(data.data.Data));
            toast.success("Login Success");
            setTimeout(() => {
                history('/dashboard');
            }, 1000);
        }
        else if(data.data.ResponseMessage =="Invalid OTP")
        {
          toast.error("OTP is Inavlid ");
        }
        
        else {
            toast.error("Invalid User");
        }
    }).catch(error => {
        toast.error("Somthing went wrong. Please try again.");
    })
}

useEffect(() => {
  const interval = setInterval(() => {
    if (seconds > 0) {
      setSeconds(seconds - 1);
    }

    if (seconds === 0) {
      if (minutes === 0) {
        clearInterval(interval);
      } else {
        setSeconds(59);
        setMinutes(minutes - 1);
      }
    }
  }, 1000);

  return () => {
    clearInterval(interval);
  };
});

    return (
            
            <Stack minH={'100vh'} direction={{ base: 'column', md: 'row' }}>
               <Toaster
                position="top-center"
                reverseOrder={false}
            />
              <Flex p={8} flex={1} align={'center'} justify={'center'}>
                <Stack spacing={4} w={'full'} maxW={'md'}>
                <img src="https://hicare.in/media/logo/stores/1/hicare-logo.png" style={{"height" : "100px","width":"200px"}}></img> 
                    {/* <Heading fontSize={'3xl'}><center>Log in</center></Heading> */}
               <Heading fontSize={'2xl'} >            
                  Customer 
                <Text as={'span'} bgGradient="linear(to-r, red.400,green.400)" bgClip="text">
                &nbsp;Mobile 
            </Text>{' '}
         
            {/* <Text as={'span'} bgGradient="linear(to-r, red.400,pink.400)" bgClip="text">
                App 
            </Text>{' '} Admin */}
            Admin
            </Heading>
            { showotpscreen == false?  
                       <div>     
                             
                  <FormControl id="email">               
                    <FormLabel>Email address</FormLabel>
                    <Input type="email"   placeholder="Your Email"  {...register("UserName",{required: "Username is Required" })}/>
                    {errors.UserName && <p style={{ color: 'red' }}>User Name is required</p>}
                  </FormControl>
                    

                  <FormControl id="password">
                    <FormLabel>Password</FormLabel>
                    <Input type="password"   placeholder="Your Password"  {...register("Password", {required: "Password is Required" })} />                
                    {errors.Password && <p style={{ color: 'red' }}>Password is required</p>}
                  </FormControl>

                  <Stack spacing={6}>
                    <Stack
                      direction={{ base: 'column', sm: 'row' }}
                      align={'start'}
                      justify={'space-between'}>
                      <Checkbox>Remember me</Checkbox>
                      {/* <Text color={'blue.500'}>Forgot password?</Text> */}
                    </Stack>
                    <Button onClick={handleSubmit(formSubmit)} bgGradient="linear(to-r, green.400,green.400)" colorScheme={'blue'} variant={'solid'}>
                      Sign in
                    </Button>
                  </Stack>
                   
                   </div>
                     : null }
                 {/* otp screen code */}
                 { showotpscreen == true ?  
                      <div>
                  <FormControl id="Login_OTP">
                    <FormLabel>User OTP</FormLabel>
                    <Input type="text"   placeholder="Enter Received OTP"  {...register("Login_OTP",{required: "OTP Required" })}/>
                    {errors.Login_OTP && <p style={{ color: 'red' }}>OTP is required</p>}
                  </FormControl>
                  <Stack spacing={6}>
                    <Stack
                      direction={{ base: 'column', sm: 'row' }}
                      align={'start'}
                      justify={'space-between'}>
                     {/* <button onClick={handleSubmit(OTPformSubmit)} color={'blue.500'}>Resend OTP</button> */}
                    {/* <button onClick={sendOTP}>Generate Otp </button> */}
        <div className="countdown-text">
          {seconds > 0 || minutes > 0 ? (
            <p>
              Time Remaining: {minutes < 10 ? `0${minutes}` : minutes}:
              {seconds < 10 ? `0${seconds}` : seconds}
            </p>
          ) : (
            <p>Didn't recieve code?</p>
          )}

          <button
            disabled={seconds > 0 || minutes > 0}
            style={{
              color: seconds > 0 || minutes > 0 ? "#DFE3E8" : "#FF5630"
            }}
            onClick={resendOTP}
          >
            Resend OTP
          </button>
          </div>
                      {/* <button onClick={handleSubmit(OTPformSubmit)} color={'blue.500'}>Resend OTP</button> */}
                    </Stack>
                  <Button onClick={handleSubmit(OTPformSubmit)} bgGradient="linear(to-r, green.400,green.400)" colorScheme={'blue'} variant={'solid'}>
                      Submit
                    </Button>
                    </Stack>
                    </div>
                       : null }

                </Stack>
              </Flex>
              <Flex flex={1}>
                <Image
                  alt={'Login Image'}
                  objectFit={'cover'}
                  src={
                    'https://hicare.in/media/wysiwyg/Hyginenew1.webp'
                   }
                />
              </Flex>
            </Stack>
          )
        }
           


const matchStateToPros = state => {
    return {
        data: state
    }
}
export default connect(matchStateToPros)(LoginComponent);