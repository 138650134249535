import axios from 'axios'
import * as CommonVariables from '../../Common/CommonVariables'
import { trackPromise } from 'react-promise-tracker';                       


//for bhk list
export const GetMenuMasterList = async () => {
    var result = await trackPromise(axios.get(CommonVariables.API_DOMAIN + `MenuMaster/GetMenuMasterList`, CommonVariables.API_HEADER)).then(response => response);
    return result;
}

//for detail by id
export const GetMenuDetailsById = async (id) => {
    var result = await trackPromise(axios.get(CommonVariables.API_DOMAIN + `MenuMaster/GetMenuListById?menuid=${id}`, CommonVariables.API_HEADER)).then(response => response);
    return result;
}

//for saving new bhk detail
export const SaveMenuDetails = async (data) => {
 
    var result = await trackPromise(axios.post(CommonVariables.API_DOMAIN + `MenuMaster/SaveMenuDetails`,JSON.stringify(data), CommonVariables.API_HEADER)).then(response => response);
    return result;
}

//for updating existing bhk detail
export const UpdateMenuDetails = async (data) => {
    var result = await trackPromise(axios.post(CommonVariables.API_DOMAIN + `MenuMaster/UpdateMenuDetails`,JSON.stringify(data), CommonVariables.API_HEADER)).then(response => response);
    return result;
}


export const DeleteMenuById = async (Id) => {
    //debugger;
    const response = await trackPromise(axios.post(CommonVariables.API_DOMAIN + `MenuMaster/DeleteMenuById?menuid=${Id}`, CommonVariables.API_HEADER))
        .then(response => response);
    return response;
}


