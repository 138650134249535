import axios from 'axios'
import * as CommonVariables from '../../Common/CommonVariables'
import { trackPromise } from 'react-promise-tracker';                       


//for bhk list
export const GetCampaignMasterList = async () => {
    var result = await trackPromise(axios.get(CommonVariables.API_DOMAIN + `CampaignMaster/GetCampaignMasterList`, CommonVariables.API_HEADER)).then(response => response);
    return result;
}

//for detail by id
export const GetCampaignListById = async (id) => {
    var result = await trackPromise(axios.get(CommonVariables.API_DOMAIN + `CampaignMaster/GetCampaignListById?campaignid=${id}`, CommonVariables.API_HEADER)).then(response => response);
    return result;
}

//for saving new bhk detail
export const SaveCampaignDetails = async (data) => {
 
    var result = await trackPromise(axios.post(CommonVariables.API_DOMAIN + `CampaignMaster/SaveCampaignDetails`,JSON.stringify(data), CommonVariables.API_HEADER)).then(response => response);
    return result;
}

//for updating existing bhk detail
export const UpdateCampaignDetails = async (data) => {
    var result = await trackPromise(axios.post(CommonVariables.API_DOMAIN + `CampaignMaster/UpdateCampaignDetails`,JSON.stringify(data), CommonVariables.API_HEADER)).then(response => response);
    return result;
}


export const DeleteCampaignById = async (Id) => {
    //debugger;
    const response = await trackPromise(axios.post(CommonVariables.API_DOMAIN + `CampaignMaster/DeleteCampaignById?campaignid=${Id}`, CommonVariables.API_HEADER))
        .then(response => response);
    return response;
}

export const GetCampaignVocherMapping = async (id) => {
    var result = await trackPromise(axios.get(CommonVariables.API_DOMAIN + `CampaignVoucherMaster/GetCampaignVoucherListById?campaignId=${id}`, CommonVariables.API_HEADER)).then(response => response);
    return result;
}

export const SaveVoucher = async (value) => {
    //debugger;
    const response = await trackPromise(axios.post(CommonVariables.API_DOMAIN + 'CampaignVoucherMaster/SaveCampaignVoucherDetails', JSON.stringify(value), CommonVariables.API_HEADER))
        .then(response => response);
    return response;
}

export const ActiveDeActiveVocuherDetail = async (Id) => {
    //debugger;
    const response = await trackPromise(axios.post(CommonVariables.API_DOMAIN + `CampaignVoucherMaster/DeleteCampaignVoucherById?campaignid=${Id}`, CommonVariables.API_HEADER))
        .then(response => response);
    return response;
}

