import axios from 'axios'
import { trackPromise } from 'react-promise-tracker';
import * as CommonVariables from '../../Common/CommonVariables'
import { store } from '../../Store/store';
const userInfo = store.getState();
export const ValidateUser = async (userName, password) => {
    var result = await trackPromise(axios.get(CommonVariables.API_DOMAIN + `UserMaster/ValidateUser?userName=${userName}&password=${password}`, CommonVariables.API_HEADER)).then(response => response);
    return result;
}
export const ValidateUserOTP = async (userloginotp, mobileno) => {
    var result = await trackPromise(axios.post(CommonVariables.API_DOMAIN + `UserMaster/ValidateOTP?OTP=${userloginotp}&mobileno=${mobileno}`, CommonVariables.API_HEADER)).then(response => response);
    return result;
}
export const ReSendUserOTP = async (mobileno) => {
    var result = await trackPromise(axios.get(CommonVariables.API_DOMAIN + `UserMaster/ResendOTP?mobileno=${mobileno}`, CommonVariables.API_HEADER)).then(response => response);
    return result;
}

