import React, { useEffect, useState, useRef } from 'react'
import { useForm } from 'react-hook-form'
import * as BHKService from '../../Services/BHK/BHKService.js'
import toast from 'react-hot-toast';
import ContainerComponent from '../Container/ContainerComponent';
import { VStack, FormControl,HStack, Tooltip,FormLabel, FormErrorMessage, Input, Checkbox, Modal, ModalOverlay, ModalContent, ModalBody, ModalHeader, ModalCloseButton, ModalFooter, useDisclosure, Flex, Stack, Heading, Table, ButtonGroup, Tfoot, Box, TableContainer, TableCaption, Spacer, Button, Thead, Tbody, Td, Tr, Th, Divider } from '@chakra-ui/react'
import { FaCopyright, FaPlus } from "react-icons/fa";
import ModalComponent from '../Modal/ModalComponent';
import { FiLayout } from 'react-icons/fi';
import { AddIcon, HamburgerIcon, EditIcon, DeleteIcon } from '@chakra-ui/icons';
import * as CommonVariables from '../../Common/CommonVariables';



function BHKComponent() {
    const { register, setValue, reset, setError, handleSubmit, formState: { errors } } = useForm({ mode: "onBlur" });
    const { isOpen: isAddOpen, onOpen: onAddOpen, onClose: onAddClose } = useDisclosure();
    const [bhklist, setBHKMasterList] = useState([]);
    const [selectedbhkid, setSelectedbhk] = useState(0);    
    const [page, setPage] = useState(1);
     

    useEffect(() => {
          LoadBHKMasterList();
    }, [])

       
    const LoadBHKMasterList = () => {
        BHKService.GetBHKMasterList().then(data => {
            if (data.data.IsSuccess) {
                debugger;
                setBHKMasterList(data.data.Data);
            } else {
                toast.error("Somthing went wrong");
            }
        }).catch(error => {
            toast.error("Somthing went wrong");
        })
    }

 
    const AddBHK = () => {
        reset({});  
        reset();     
        setSelectedbhk(0);
        onAddOpen();
        
    }

 
    const SaveBHKDetail = (value) => {
      
        if (selectedbhkid <= 0) {
            BHKService.SaveBHKDetails(value).then(data => {
                if (data.data.IsSuccess) {
                    toast.success("Success.");
                    onAddClose();
                    LoadBHKMasterList();
                } else {
                    toast.error("Somthing went wrong.");
                }
            }).catch(error => {
                toast.error("Somthing went wrong.");
            })
        } else {
            value.Id = selectedbhkid;
            BHKService.UpdateBHKDetails(value).then(data => {
                if (data.data.IsSuccess) {
                    toast.success("Success");
                    onAddClose();
                    LoadBHKMasterList();
                } else {
                    toast.error("Somthing went wrong.");
                }
            }).catch(error => {
                toast.error("Somthing went wrong.");
            })
        }
    }

    const EditbhkMaster = (id) => {
        setSelectedbhk(id);
        reset({});
        BHKService.GetBHKDetailsById(id).then(data => {
            if (data.data.IsSuccess) {
                reset(data.data.Data);
                onAddOpen();
            } else {
                toast.error("Somthing went wrong");
            }
        }).catch(error => {
            toast.error("Somthing went wrong");
        })
    }

    const DeleteBHK = (id) => {
        debugger;
        var action = window.confirm('Do you want to delete BHK?');
        if (action == true) {
            BHKService.DeleteBHKById(id).then(data => {
                if (data.data.IsSuccess) {
                    console.log(data.data)
                    toast.success(data.data.ResponseMessage);
                    LoadBHKMasterList();
                }
            }).catch(error => {

            })
        }
    }

   
    

    return (




        <ContainerComponent Title="BHK Master List" Icon={<FiLayout />} ShowDownloadAction="false" ShowAction="true" BtnIcon={<FaPlus />} ButtonTitle="BHK" AddNewAction={AddBHK}>
            <TableContainer>
                <Table variant='simple'>
                    <Thead>
                        <Tr>
                        <Th>Id</Th>
                            <Th>BHK Name</Th>
                            <Th>Sequence No</Th>                          
                            <Th>Is Active</Th>
                            <Th>Action</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {bhklist.map((data, index) => {
                            return (<Tr key={index}>
                                <Td>{data.Id}</Td>
                                <Td>{data.No_Of_BHK}</Td>
                                <Td>{data.Sequence_No}</Td>
                            
                                <Td>
                                    <Checkbox colorScheme={data.Is_Active ? 'green' : 'red'} defaultChecked isReadOnly>
                                    </Checkbox>
                                </Td>
                                <Td>
                                <ButtonGroup variant={"outline"} spacing="1">
                <Tooltip label='Edit BHK' placement='auto-start'>
                    <Button colorScheme="blue" variant="solid" size={"xs"} onClick={() => EditbhkMaster(data.Id)} >
                        <EditIcon />
                    </Button >
                </Tooltip>
                <Tooltip label='Delete BHK' placement='auto-start'>
                    <Button colorScheme="red" variant="solid" size={"xs"} onClick={() => DeleteBHK(data.Id)}>
                        <DeleteIcon />
                    </Button>
                </Tooltip>
              
            </ButtonGroup >
                                </Td>
                            </Tr>)
                        })}
                    </Tbody>
                    {bhklist.length <= 0 ? (
                        <Tfoot>
                            <Tr colSpan="3">
                                <Td>
                                    No Data Available
                                </Td>
                            </Tr>
                        </Tfoot>
                    ) : <></>
                    }

                </Table>
            </TableContainer>
            {/* <Table
                size="sm"
                emptyData={{
                    text: "No Data Available."
                }}
                colorScheme="blue"
                totalRegisters={BHKList.length}
                page={page}
                onPageChange={(page) => setPage(page)}
                columns={tableColumns}
                data={tableData}
            /> */}
            <ModalComponent size="2xl" Title="Add/Update BHK" isOpen={isAddOpen} onOpen={onAddOpen} onClose={onAddClose} onSave={handleSubmit(SaveBHKDetail)} >
                <Stack spacing={4}>
                    <HStack>
                        <Box width={"full"}>
                            <FormControl isRequired isInvalid={errors.No_Of_BHK}>
                                <FormLabel>No Of BHK</FormLabel>
                                <Input placeholder='Enter No Of BHK' {...register("No_Of_BHK", { required: "Field is Mandatory" })} />
                                <FormErrorMessage>
                                    {errors.No_Of_BHK && errors.No_Of_BHK.message}
                                </FormErrorMessage>
                            </FormControl>
                        </Box>
                        <Box width={"full"}>
                            <FormControl isRequired isInvalid={errors.Sequence_No}>
                                <FormLabel>Enter Sequence No</FormLabel>
                                <Input placeholder='Enter Sequence No' {...register("Sequence_No", { required: "Field is Mandatory" })} />
                                <FormErrorMessage>
                                    {errors.Sequence_No && errors.Sequence_No.message}
                                </FormErrorMessage>
                            </FormControl>
                        </Box>
                       
                    </HStack>                                 
                  
                
                    <HStack>
                        <Box width={"full"}>
                            {
                                selectedbhkid > 0 ? (
                                    <FormControl>
                                        <FormLabel>Is Active</FormLabel>
                                        <Checkbox colorScheme='green'  {...register("Is_Active",)}>
                                        </Checkbox>
                                        <FormErrorMessage>
                                        </FormErrorMessage>
                                    </FormControl>
                                ) : <></>
                            }
                        </Box>
                    </HStack>
                </Stack>
            </ModalComponent>
            {/* mapping part starts */}

           
        </ContainerComponent >
    )
}

export default BHKComponent