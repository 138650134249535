import React, { useEffect, useState, useRef } from 'react'
import { useForm } from 'react-hook-form'
import * as campaignMasterService from '../../Services/CampaignMaster/CampaignMasterService';
import * as ServicePlanMasterService from '../../Services/ServicePlan/ServicePlanService';

import toast from 'react-hot-toast';
import ContainerComponent from '../Container/ContainerComponent';
import { VStack, FormControl,HStack, Tooltip,FormLabel,CheckboxGroup, Select,FormErrorMessage, Input, Checkbox, Modal, ModalOverlay, ModalContent, ModalBody, ModalHeader, ModalCloseButton, ModalFooter, useDisclosure, Flex, Stack, Heading, Table, ButtonGroup, Tfoot, Box, TableContainer, TableCaption, Spacer, Button, Thead, Tbody, Td, Tr, Th, Divider,Textarea } from '@chakra-ui/react'
import { FaCopyright, FaPlus } from "react-icons/fa";
import ModalComponent from '../Modal/ModalComponent';
import { FiLayout } from 'react-icons/fi';
import { AddIcon, HamburgerIcon, EditIcon, DeleteIcon } from '@chakra-ui/icons';
import * as CommonVariables from '../../Common/CommonVariables';
import * as AttachmentService from "../../Services/Attachment/AttachmentService";
import FilePicker from "chakra-ui-file-picker";
import * as moment from "moment";
import ReactDatePicker from "react-datepicker";
import Multiselect from "multiselect-react-dropdown";


function CampaignMasterComponent() {
    const {handleSubmit: handleVoucherSubmit,register: registerVoucher,setValue: setValueVoucher,reset: resetVoucher,
        formState: { errors: errorVoucher },
      } = useForm({ mode: "onBlur" });
    const { isOpen: isVoucherOpen,onOpen: onVoucherOpen,onClose: onVoucherClose,} = useDisclosure();
    const { register, setValue, reset, setError, handleSubmit, formState: { errors } } = useForm({ mode: "onBlur" });
    const { isOpen: isAddOpen, onOpen: onAddOpen, onClose: onAddClose } = useDisclosure();
    const [campaignlist, setcampaignMasterList] = useState([]);
    const [CampaignMappingList, setCampaignMappingList] = useState([]);  
    const [selectedcampaignid, SetSelectedcampaign] = useState(0); 
    const [SelectedserviceplanList, setSelectedServiceplanList] = useState([]);
    const [ServiceplanList, setServicePlanList] = useState([]);
    const [page, setPage] = useState(1);
    const [Campaign_Start_Date, setStartDate] = useState(new Date());
    const [Campaign_End_Date, setEndDate] = useState(new Date());
    const [checkedItems, setCheckedItems] = React.useState([false, false]);
    const allChecked = checkedItems.every(Boolean);
    const isIndeterminate = checkedItems.some(Boolean) && !allChecked;
    const [PopUpImage, setPopUpImage] = useState('');
    const [ShowServicePlan, setShowServicePlan] = useState(false);
    const daysOfWeek = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];

    useEffect(() => {
          LoadcampaignMasterList();
          GetServicePlanList();
    }, [])
    
    

 
    const Addcampaign = () => {
        reset({});      
        reset();  
        SetSelectedcampaign(0);
        onAddOpen();
        setSelectedDays([]);
        setValue("Id", 0);
        setPopUpImage('');
        setStartDate(new Date());
        setEndDate(new Date());
    }   
    const onCampaignTypeChange = (value) => {
        // e.preventDefault();
        if (value == "Service Plan") {
          setShowServicePlan(true);
          setValue("Minimum_Order_Value", 0);
          
        } else {
          setSelectedServiceplanList([]);
          setShowServicePlan(false);
        }
      };
      const updateServicePlanList = (data) => {
        setSelectedServiceplanList(data);
        console.log(setSelectedServiceplanList);
      };
    const DayCheckbox = ({ day, isChecked, onChange }) => (
        <Checkbox
          isChecked={isChecked}
          onChange={onChange}>
          {day}
        </Checkbox>
      );

      const GetServicePlanList = () => {
        ServicePlanMasterService.GetServicePlanMasterList()
          .then((data) => {
            if (data.data.IsSuccess) {
              debugger;
              setServicePlanList(data.data.Data);
            } else {
              //toast.error("Somthing went wrong.");
            }
          })
          .catch((error) => {
            //toast.error("Somthing went wrong.");
          });
      };
       
    const LoadcampaignMasterList = () => {
        campaignMasterService.GetCampaignMasterList().then(data => {
            if (data.data.IsSuccess) {
                debugger;
                setcampaignMasterList(data.data.Data);
            } else {
                toast.error("Somthing went wrong");
            }
        }).catch(error => {
            toast.error("Somthing went wrong");
        })
    }
    let handleColor = (time) => {
        return time.getHours() > 12 ? "text-success" : "text-error";
      };
    const [selectedDays, setSelectedDays] = useState([]);
    const allDaysSelected = selectedDays.length === daysOfWeek.length;

  const handleDayChange = (selected) => {
    debugger;
    setSelectedDays(selected);
  };
  function clickHandler(stDate, endDate) {
    const startdate = new Date(stDate);
    setStartDate(startdate);
    const edDate = new Date(endDate);
    setEndDate(edDate);
  }
  const handleAllDaysChange = (isChecked) => {
    debugger;
    if (isChecked) {
      if (allDaysSelected) {
        setSelectedDays([]);
      } else {
        setSelectedDays(daysOfWeek);
      }
    }
  };

    const SavecampaignDetail = (value) => {
        debugger;
    if (validateCampaign(value)) {
      value.ServicePlanList = SelectedserviceplanList;
       value.DayList = selectedDays;
      value.Image_URL = PopUpImage;
      value.Campaign_Start_Date = moment(Campaign_Start_Date).format();
      value.Campaign_End_Date = moment(Campaign_End_Date).format();
      if (parseInt(value.Id) > 0) {
        campaignMasterService.UpdateCampaignDetails(value)
          .then((data) => {
            debugger;
            if (data.data.IsSuccess) {
              toast.success("Campaign Update");
              onAddClose();
              LoadcampaignMasterList();
            } else {
              toast.error("Something went wrong");
            }
          })
          .catch((error) => {});
      } else {
        console.log(value);
        campaignMasterService.SaveCampaignDetails(value)
          .then((data) => {
            debugger;
            if (data.data.IsSuccess) {
              toast.success("Campaign Save");
              onAddClose();
              LoadcampaignMasterList();
            } else {
              toast.error("Something went wrong");
            }
          })
          .catch((error) => {});
      }
    }

      
    }

    
    const EditcampaignMaster = (id) => {        
        setPopUpImage('');      
        campaignMasterService.GetCampaignListById(id).then(data => {
            if (data.data.IsSuccess) {
                reset(data.data.Data);
               clickHandler(
                    data.data.Data.Campaign_Start_Date,
                    data.data.Data.Campaign_End_Date
                  );
                  debugger;
                  setSelectedDays(data.data.Data.DayList);
                  if(data.data.Data.Is_PopupCampaign == true){
                    setPopUpImage(data.data.Data.Image_URL);
                  }
                  if (data.data.Data.Campaign_Type == "Service Plan") {
                    setShowServicePlan(true);
                    setSelectedServiceplanList(data.data.Data.ServicePlanList);
                  } else {
                    setShowServicePlan(false);
                  }
                onAddOpen();
            } else {
                toast.error("Somthing went wrong");
            }
        }).catch(error => {
            toast.error("Somthing went wrong");
        })
    }
 
    const Deletecampaign = (id) => {
        debugger;
        var action = window.confirm('Do you want to delete Campaign?');
        if (action == true) {
            campaignMasterService.DeleteCampaignById(id).then(data => {
                if (data.data.IsSuccess) {
                    console.log(data.data)
                    toast.success(data.data.ResponseMessage);
                    LoadcampaignMasterList();
                }
            }).catch(error => {

            })
        }
    }
    const validateCampaign = (data) => {
        debugger;
        if (ShowServicePlan && SelectedserviceplanList.length == 0) {
          toast.error("Select Service Plan");
          return false;
        }
        if (!ShowServicePlan && data.Minimum_Order_Value <= 0) {
          toast.error("Check value of Minimum Order Value");
          return false;
        }
        if (data.Discount_Type == "Percentage" && data.Discount_Value > 100) {
          toast.error("Discount Value cannot be greater than 100%");
          return false;
        }
        if (selectedDays.length == 0) {
          toast.error("Please select atleast one option from the day section");
          return false;
        }
    
        if (data.Timer_Campaign == true && data.Is_PopupCampaign == true) {
          toast.error("Please select either 'Timer_Campaign' or 'PopUp Campaign'");
          return false;
        }
    
        if(data.Timer_Campaign == true && PopUpImage != ''){
          toast.error("Image can be uploaded when 'PopUp Campaign' is selected.");
          return false;
        }
    
        if(data.Is_PopupCampaign == true && PopUpImage == ''){
          toast.error("Please upload one image.");
          return false;
        }
    
        return true;
      };

    const PopUpFileUploadToS3 = (fileList) => {
        debugger;
        if (fileList != null && fileList.length > 0) {
          var formdata = new FormData();
          formdata.append("Myfile", fileList[0]);
    
          AttachmentService.FileUploadToS3(formdata)
            .then((response) => {
              if (response.data.IsSuccess) {
                toast.success(response.data.ResponseMessage);
                setPopUpImage(response.data.Data);
              } else {
                setPopUpImage("");
                toast.error(response.data.ResponseMessage);
              }
            })
            .catch((error) => {
              toast.error("Something went wrong!");
            });
        } else {
          toast.error("Please choose Image First!");
        }
      };

      const GetCampaignVocherMapping = (id) => {
        debugger;
        resetVoucher({});
        SetSelectedcampaign(id);
        campaignMasterService.GetCampaignVocherMapping(id)
          .then((data) => {
            if (data.data.IsSuccess) {
              debugger;
              setCampaignMappingList(data.data.Data);
              console.log(data.data.Data);
              onVoucherOpen();
            }
          })
          .catch((error) => {});
      };

      const saveVoucherDetails = (value) => {
        debugger;
        value.Campaign_Id = selectedcampaignid;
        campaignMasterService.SaveVoucher(value)
          .then((data) => {
            debugger;
            if (data.data.IsSuccess) {
              toast.success("Voucher Save");
              GetCampaignVocherMapping(selectedcampaignid);
            } else {
              toast.error(data.data.ResponseMessage);
            }
          })
          .catch((error) => {});
      };

      const DeleteVoucher = (id) => {
        debugger;
        campaignMasterService.ActiveDeActiveVocuherDetail(id)
          .then((data) => {
            if (data.data.IsSuccess) {
              toast.success(data.data.ResponseMessage);
              GetCampaignVocherMapping(selectedcampaignid);
            } else {
              GetCampaignVocherMapping([]);
            }
          })
          .catch((error) => {});
      };

    return (

        <ContainerComponent Title="Campaign Master List" Icon={<FiLayout />} ShowDownloadAction="false" ShowAction="true" BtnIcon={<FaPlus />} ButtonTitle="Campaign" AddNewAction={Addcampaign}>
            <TableContainer>
                <Table variant='simple'>
                    <Thead>
                        <Tr>
                        <Th>Id</Th>
                            <Th>Campaign Name</Th>
                            <Th>Min Order</Th>  
                            <Th>Validity</Th> 
                            <Th>Discount</Th>   
                            <Th>Is Active</Th>  
                            <Th>Action</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {campaignlist.map((data, index) => {
                            return (<Tr key={index}>
                                <Td>{data.Id}</Td>
                                <Td>{data.Campaign_Name}</Td>
                                <Td>₹{data.Minimum_Order_Value}</Td>
                                <Td>
                    {moment(data.Campaign_Start_Date).format("DD-MMM-YYYY")} -{" "}
                    {moment(data.Campaign_End_Date).format("DD-MMM-YYYY")}
                  </Td>
                  <Td>
                    {data.Discount_Type == "Cash" ? "₹" : ""}{" "}
                    {data.Discount_Value}{" "}
                    {data.Discount_Type == "Percentage" ? "%" : ""}
                  </Td>
                                                            
                                { <Td>
                                    <Checkbox colorScheme={data.Is_Active ? 'green' : 'red'} defaultChecked isReadOnly>
                                    </Checkbox>
                                </Td> }
                                <Td>
                    <ButtonGroup
                      variant={"outline"}
                      spacing="1">
                      <Tooltip
                        label="Edit Campaign"
                        placement="auto-start">
                        <Button
                          colorScheme="blue"
                          variant="solid"
                          size={"xs"}
                          onClick={() => EditcampaignMaster(data.Id)}>
                          <EditIcon />
                        </Button>
                      </Tooltip>
                    </ButtonGroup>
                    &nbsp;&nbsp;
                    <ButtonGroup
                      variant={"outline"}
                      spacing="1">
                      <Tooltip
                        label="Delete Campaign"
                        placement="auto-start">
                        <Button
                          colorScheme="red"
                          variant="solid"
                          size={"xs"}
                          onClick={() =>
                            Deletecampaign(data.Id)
                          }>
                          <DeleteIcon />
                        </Button>
                      </Tooltip>
                    </ButtonGroup>
                    &nbsp;&nbsp;
                    <ButtonGroup
                      variant={"outline"}
                      spacing="1">
                      <Tooltip
                        label="Campaign Voucher Mapping"
                        placement="auto-start">
                        <Button
                          colorScheme="whatsapp"
                          variant="solid"
                          size={"xs"}
                          onClick={() => GetCampaignVocherMapping(data.Id)}>
                          <HamburgerIcon />
                        </Button>
                      </Tooltip>
                    </ButtonGroup>
                  </Td>
                            </Tr>)
                        })}
                    </Tbody>
                    {campaignlist.length <= 0 ? (
                        <Tfoot>
                            <Tr colSpan="3">
                                <Td>
                                    No Data Available
                                </Td>
                            </Tr>
                        </Tfoot>
                    ) : <></>
                    }

                </Table>
            </TableContainer>
          
            <ModalComponent
        Title="Add/Update Campaign"
        size={"6xl"}
        isOpen={isAddOpen}
        onOpen={onAddOpen}
        onClose={onAddClose}
        onSave={handleSubmit(SavecampaignDetail)}>
        <Input
          hidden
          {...register("Id")}
        />
        <Stack spacing={4}>
          <HStack>
            <Box width={"40%"}>
              <FormControl
                isRequired
                isInvalid={errors.Campaign_Code}>
                <FormLabel>Campaign Code</FormLabel>
                <Input
                  placeholder="Campaign Code"
                  {...register("Campaign_Code", {
                    required: "Campaign Code is Mandatory",
                  })}
                />
                <FormErrorMessage>
                  {errors.Campaign_Code && errors.Campaign_Code.message}
                </FormErrorMessage>
              </FormControl>
            </Box>
            <Box width={"full"}>
              <FormControl
                isRequired
                isInvalid={errors.Campaign_Name}>
                <FormLabel>Campaign Name</FormLabel>
                <Input
                  placeholder="Campaign Name"
                  {...register("Campaign_Name", {
                    required: "Campaign Name is Mandatory",
                  })}
                />
                <FormErrorMessage>
                  {errors.Campaign_Name && errors.Campaign_Name.message}
                </FormErrorMessage>
              </FormControl>
            </Box>
          </HStack>
          <HStack>
            <Box width={"full"}>
              <FormControl
                isRequired
                isInvalid={errors.Campaign_Type}>
                <FormLabel>Campaign Type</FormLabel>
                <Select
                  className="form-control"
                  {...register("Campaign_Type", {
                    required: "Campaign Type is Mandatory",
                  })}
                  onChange={(e) => onCampaignTypeChange(e.target.value)}>
                  <option value="">Select Campaign Type</option>
                  {CommonVariables.Campaign_Type.map((data, index) => {
                    return (
                      <option
                        value={data.Value}
                        key={index}>
                        {data.Key}
                      </option>
                    );
                  })}
                </Select>
                <FormErrorMessage>
                  {errors.Campaign_Type && errors.Campaign_Type.message}
                </FormErrorMessage>
              </FormControl>
            </Box>
           
            {ShowServicePlan ? (
              <Box width={"full"}>
                <FormControl>
                  <FormLabel>Serviceplan List</FormLabel>
                  <Multiselect
                    placeholder="Select Service Plan"
                    options={ServiceplanList}
                    selectedValues={SelectedserviceplanList}
                    onSelect={updateServicePlanList}
                    onRemove={updateServicePlanList}
                    displayValue="Service_Plan_Name"></Multiselect>
                </FormControl>
              </Box>
            ) : (
              <Box width={"full"}>
                <FormControl>
                  <FormLabel>Minimum Order Value</FormLabel>
                  <Input
                    type="number"
                    placeholder="Minimum Order Value"
                    {...register("Minimum_Order_Value", {
                      required: "Minimum Order Value is Mandatory",
                      setValueAs: (v) => parseInt(v),
                    })}
                  />
                </FormControl>
              </Box>
               )}
          </HStack>
          <HStack>
            <Box width={"full"}>
              <FormControl
                isRequired
                isInvalid={errors.Discount_Type}>
                <FormLabel>Discount Type</FormLabel>
                <Select
                  className="form-control"
                  {...register("Discount_Type", {
                    required: "Discount Type is Mandatory",
                  })}>
                  <option value="">Select Discount Type</option>
                  {CommonVariables.Discount_Type.map((data, index) => {
                    return (
                      <option
                        value={data.Value}
                        key={index}>
                        {data.Key}
                      </option>
                    );
                  })}
                </Select>
                <FormErrorMessage>
                  {errors.Discount_Type && errors.Discount_Type.message}
                </FormErrorMessage>
              </FormControl>
            </Box>
            <Box width={"full"}>
              <FormControl
                isRequired
                isInvalid={errors.Discount_Value}>
                <FormLabel>Discount Value</FormLabel>
                <Input
                  placeholder="Discount Value"
                  {...register("Discount_Value", {
                    required: "Discount Value is Mandatory",
                    setValueAs: (v) => parseInt(v),
                  })}
                />
                <FormErrorMessage>
                  {errors.Discount_Value && errors.Discount_Value.message}
                </FormErrorMessage>
              </FormControl>
            </Box>
          </HStack>
          <HStack>
            <Box width={"full"}>
              <FormControl isRequired>
                <FormLabel>Discount StartDate</FormLabel>

                <ReactDatePicker
                  valueName="selected" // DateSelect value's name is selected
                  selected={Campaign_Start_Date}
                  onChange={(date) => setStartDate(date)}
                  name="ReactDatepicker"
                  showTimeSelect
                  timeClassName={handleColor}
                  className="form-control"
                  placeholderText="Select Start date"
                  dateFormat="dd-MM-yyyy hh:mm aa"
                  value={Campaign_Start_Date}
                />
              </FormControl>
            </Box>
            <Box width={"full"}>
              <FormControl isRequired>
                <FormLabel>Discount EndDate</FormLabel>

                <ReactDatePicker
                  valueName="selected" // DateSelect value's name is selected
                  selected={Campaign_End_Date}
                  onChange={(date) => setEndDate(date)}
                  name="ReactDatepicker"
                  showTimeSelect
                  timeClassName={handleColor}
                  className="form-control"
                  placeholderText="Select End date"
                  dateFormat="dd-MM-yyyy hh:mm aa"
                  value={Campaign_End_Date}
                />
              </FormControl>
            </Box>
            <Box width={"50%"}>
              <FormControl>
                <FormLabel>Timer Campaign</FormLabel>
                <Checkbox
                  colorScheme="green"
                  defaultChecked={false}
                  {...register("Timer_Campaign")}></Checkbox>
                <FormErrorMessage></FormErrorMessage>
              </FormControl>
            </Box>
            <Box width={"50%"}>
              <FormControl>
                <FormLabel>PopUp Campaign</FormLabel>
                <Checkbox
                  colorScheme="green"
                  defaultChecked={false}
                  {...register("Is_PopupCampaign")}></Checkbox>
                <FormErrorMessage></FormErrorMessage>
              </FormControl>
            </Box>
          </HStack>
          <HStack>          

           
              <Box width={"full"}>
                <FormControl
                  isRequired
                  isInvalid={errors.Campaign_Display_Title}>
                  <FormLabel>Campaign Display Title</FormLabel>
                  <Input
                    placeholder="Campaign Display Title"
                    {...register("Campaign_Display_Title", {
                      required: "Campaign Display Title is Mandatory",
                    })}
                  />
                  <FormErrorMessage>
                    {errors.Campaign_Display_Title &&
                      errors.Campaign_Display_Title.message}
                  </FormErrorMessage>
                </FormControl>
              </Box>
         
            <Box width={"full"}>
              <FormControl
                isRequired
                isInvalid={errors.PopUp_Image}>
                <FormLabel>PopUp Image Campaign</FormLabel>

                <FilePicker
                  onFileChange={(fileList) => {
                    PopUpFileUploadToS3(fileList);
                  }}
                  placeholder="PopUp_Image"
                  clearButtonLabel="Clear"
                  multipleFiles={false}
                  accept="application/png"
                  hideClearButton={false}
                />
                <FormErrorMessage>
                  {errors.PopUpImage && errors.PopUpImage.message}
                </FormErrorMessage>
              </FormControl>
              <span className="NoteImageSize"> Note : Size W-400px * H-450px</span>
            </Box>
            <Box width={"50%"}>
            <a href={PopUpImage} target="blank"><img src={PopUpImage} width='50px' height='50px' /></a>
            </Box>
          </HStack>
          <HStack>
            <Box width={"full"}>
              <FormControl isRequired>
                <FormLabel>Select Days</FormLabel>
                <CheckboxGroup
                  colorScheme="blue"
                  value={selectedDays}
                  onChange={handleDayChange}>
                  <HStack>
                    <Checkbox
                      isChecked={allDaysSelected}
                      onChange={handleAllDaysChange}>
                      All
                    </Checkbox>
                    {daysOfWeek.map((day) => (
                      <DayCheckbox
                        key={day}
                        day={day}
                        isChecked={selectedDays.includes(day)}
                        onChange={(e) => {
                          debugger;
                          if (e.target.checked) {
                            setSelectedDays([...selectedDays, day]);
                          } else {
                            setSelectedDays(
                              selectedDays.filter(
                                (selectedDay) => selectedDay !== day
                              )
                            );
                          }
                        }}
                      />
                    ))}
                  </HStack>
                </CheckboxGroup>
              </FormControl>
            </Box>
          </HStack>

          <Checkbox
            hidden
            defaultChecked
            {...register("Is_Active")}>
            {" "}
          </Checkbox>
        </Stack>
      </ModalComponent>

      <ModalComponent
        Title="Campaign Voucher Mapping"
        scroll="inside"
        size={"3xl"}
        isOpen={isVoucherOpen}
        onOpen={onVoucherOpen}
        onClose={onVoucherClose}>
        <Stack>
          <Box spacing={4}>
            <HStack>
              <Box width={"full"}>
                <FormControl
                  isRequired
                  isInvalid={errorVoucher.Voucher_Code}>
                  <FormLabel>Voucher Code</FormLabel>
                  <Input
                    placeholder="Voucher Code"
                    {...registerVoucher("Voucher_Code", {
                      required: "Voucher Code is Mandatory",
                    })}
                  />
                  <FormErrorMessage>
                    {errorVoucher.Voucher_Code &&
                      errorVoucher.Voucher_Code.message}
                  </FormErrorMessage>
                </FormControl>
              </Box>
              <Box
                width={"50%"}
                paddingTop={6}>
                <FormControl
                  isRequired
                  isInvalid={errorVoucher.Max_Use_Count}>
                  <FormLabel>Max Use Count</FormLabel>
                  <Input
                    type="number"
                    placeholder="Max Use Count"
                    {...registerVoucher("Max_Use_Count", {
                      required: "Max Use Count is Mandatory",
                      min: 1,
                      setValueAs: (v) => parseInt(v),
                    })}
                  />
                  <FormErrorMessage>
                    {errorVoucher.Max_Use_Count &&
                      errorVoucher.Max_Use_Count.message}
                  </FormErrorMessage>
                  
                  <span style={{fontSize: 12,color: "red"}}>
                    {" "}
                    Note : Count cannot be Zero
                  </span>
                </FormControl>
              </Box>
            </HStack>
            <HStack
              paddingTop={"5"}
              float="right">
              <ButtonGroup
                variant="outline"
                spacing="4">
                <Button
                  type="submit"
                  onClick={handleVoucherSubmit(saveVoucherDetails)}
                  colorScheme="whatsapp">
                  Save Voucher
                </Button>
              </ButtonGroup>
            </HStack>
          </Box>
        </Stack>

        <br />
        <TableContainer>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>Id</Th>
                <Th>Voucher Code</Th>
                <Th>Max Use</Th>
                <Th>Used Count</Th>
                <Th>Action</Th>
              </Tr>
            </Thead>
            <Tbody>
         
              {CampaignMappingList.map((cmap, index) => {
                return (
                  <Tr key={index}>
                    <Td>{cmap.Id}</Td>
                    <Td>{cmap.Voucher_Code}</Td>
                    <Td>{cmap.Max_Use_Count}</Td>
                    <Td>{cmap.Used_Count}</Td>
                    <Td>
                      <ButtonGroup
                        variant={"outline"}
                        spacing="1">
                        <Button
                          colorScheme="blue"
                          variant="solid"
                          size={"xs"}
                          onClick={() =>
                            DeleteVoucher(cmap.Id)
                          }>
                          <DeleteIcon />
                        </Button>
                      </ButtonGroup>
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
            {CampaignMappingList.length <= 0 ? (
              <Tfoot>
                <Tr colSpan="2">
                  <Td>No Data Available</Td>
                </Tr>
              </Tfoot>
            ) : (
              <></>
            )}
          </Table>
        </TableContainer>
      </ModalComponent>
    </ContainerComponent>
  );
}
export default CampaignMasterComponent