import React, { useEffect, useState, useRef } from 'react'
import { useForm } from 'react-hook-form'
import * as FAQService from '../../Services/ServiceFAQ/FAQService'
import toast from 'react-hot-toast';
import ContainerComponent from '../Container/ContainerComponent';
import { VStack, FormControl,HStack, Tooltip,FormLabel, Select,FormErrorMessage, Input, Checkbox, Modal, ModalOverlay, ModalContent, ModalBody, ModalHeader, ModalCloseButton, ModalFooter, useDisclosure, Flex, Stack, Heading, Table, ButtonGroup, Tfoot, Box, TableContainer, TableCaption, Spacer, Button, Thead, Tbody, Td, Tr, Th, Divider } from '@chakra-ui/react'
import { FaCopyright, FaPlus } from "react-icons/fa";
import ModalComponent from '../Modal/ModalComponent';
import { FiLayout } from 'react-icons/fi';
import { AddIcon, HamburgerIcon, EditIcon, DeleteIcon } from '@chakra-ui/icons';
import * as CommonVariables from '../../Common/CommonVariables';
import * as AttachmentService from "../../Services/Attachment/AttachmentService";
import FilePicker from "chakra-ui-file-picker";
import * as MasterService  from '../../Services/ServiceMaster/ServiceMasterService'
import JoditEditor from "jodit-react";


function FAQComponent() {
    const { register, setValue, reset, setError, handleSubmit, formState: { errors } } = useForm({ mode: "onBlur" });
    const { isOpen: isAddOpen, onOpen: onAddOpen, onClose: onAddClose } = useDisclosure();
    const [faqlist, setFAQMasterList] = useState([]);
    const [selectedfaqid, setSelectedFAQ] = useState(0);    
    const [page, setPage] = useState(1);
    const [servicelist, setMasterList] = useState([]);
    const [servicefaqdetail, setServiceFAQDetail] = useState('');
    const servicefaqeditor = useRef('');

    const shortdescrip_config = { //Short_DetailDescription
        readonly: false, // all options from https://xdsoft.net/jodit/doc/
        height: 300
    };

    useEffect(() => {
          LoadFAQMasterList();
          LoadServiceMasterList();
    }, [])

       
    const LoadServiceMasterList = () => {
        MasterService.GetServiceMasterList().then(data => {
            if (data.data.IsSuccess) {
                debugger;
                setMasterList(data.data.Data);
            } else {
                toast.error("Somthing went wrong");
            }
        }).catch(error => {
            toast.error("Somthing went wrong");
        })
    }
    const LoadFAQMasterList = () => {
        FAQService.GetServiceFAQMasterList().then(data => {
            if (data.data.IsSuccess) {
                debugger;
                setFAQMasterList(data.data.Data);
            } else {
                toast.error("Somthing went wrong");
            }
        }).catch(error => {
            toast.error("Somthing went wrong");
        })
    }

 
    const AddFAQ = () => {
        reset({});    
        reset();    
        setSelectedFAQ(0);
        onAddOpen();
        setServiceFAQDetail('');
       

        
    }
   
 
    const SaveFAQDetail = (value) => {
        debugger;
        if (servicefaqeditor.current.value == "" || servicefaqeditor.current.value == null) {
            toast.error('Service FAQ Detail is mandatory!')
            return false;
        }
       
       else if (selectedfaqid <= 0 ) {
          value.FAQ_Detail = servicefaqeditor.current.value;
            FAQService.SaveServiceFAQDetails(value).then(data => {
                if (data.data.IsSuccess) {
                    toast.success("Success.");
                    onAddClose();
                    LoadFAQMasterList();
                } else {
                    toast.error("Somthing went wrong.");
                }
            }).catch(error => {
                toast.error("Somthing went wrong.");
            })
        } else {
            value.Id = selectedfaqid;
         
            FAQService.UpdateServiceFAQDetails(value).then(data => {
                if (data.data.IsSuccess) {
                    toast.success("Success");
                    onAddClose();
                    LoadFAQMasterList();
                } else {
                    toast.error("Somthing went wrong.");
                }
            }).catch(error => {
                toast.error("Somthing went wrong.");
            })

    }

    }

    const EditFAQMaster = (id) => {
        setSelectedFAQ(id);
        reset({});
        FAQService.GetServiceFAQListById(id).then(data => {
            if (data.data.IsSuccess) {
                reset(data.data.Data);               
                onAddOpen();
                setServiceFAQDetail(data.data.Data.FAQ_Detail);
            } else {
                toast.error("Somthing went wrong");
            }
        }).catch(error => {
            toast.error("Somthing went wrong");
        })
    }

    const DeleteFAQ = (id) => {
        debugger;
        var action = window.confirm('Do you want to delete FAQ?');
        if (action == true) {
            FAQService.DeleteServiceFAQById(id).then(data => {
                if (data.data.IsSuccess) {
                    console.log(data.data)
                    toast.success(data.data.ResponseMessage);
                    LoadFAQMasterList();
                }
            }).catch(error => {

            })
        }
    }

   

    return (




        <ContainerComponent Title="FAQ Master List" Icon={<FiLayout />} ShowDownloadAction="false" ShowAction="true" BtnIcon={<FaPlus />} ButtonTitle="FAQ" AddNewAction={AddFAQ}>
            <TableContainer>
                <Table variant='simple'>
                    <Thead>
                        <Tr>
                        <Th>Id</Th>
                            <Th>FAQ Type</Th>
                            <Th>Service Name</Th>  
                            <Th>Sequence No</Th> 
                            <Th>Is Active</Th>                         
                            <Th>Action</Th>
                         
                        </Tr>
                    </Thead>
                    <Tbody>
                        {faqlist.map((data, index) => {
                            return (<Tr key={index}>
                                <Td>{data.FAQ_Id}</Td>
                                <Td>{data.FAQ_Type}</Td>
                                <Td>{data.Service_Name}</Td>
                                <Td>{data.Sequence_No}</Td>
                            
                                <Td>
                                    <Checkbox colorScheme={data.Is_Active ? 'green' : 'red'} defaultChecked isReadOnly>
                                    </Checkbox>
                                </Td>
                                <Td>
                                <ButtonGroup variant={"outline"} spacing="1">
                <Tooltip label='Edit FAQ' placement='auto-start'>
                    <Button colorScheme="blue" variant="solid" size={"xs"} onClick={() => EditFAQMaster(data.FAQ_Id)} >
                        <EditIcon />
                    </Button >
                </Tooltip>
                <Tooltip label='Delete FAQ' placement='auto-start'>
                    <Button colorScheme="red" variant="solid" size={"xs"} onClick={() => DeleteFAQ(data.FAQ_Id)}>
                        <DeleteIcon />
                    </Button>
                </Tooltip>
              
            </ButtonGroup >
                                </Td>
                            </Tr>)
                        })}
                    </Tbody>
                    {faqlist.length <= 0 ? (
                        <Tfoot>
                            <Tr colSpan="3">
                                <Td>
                                    No Data Available
                                </Td>
                            </Tr>
                        </Tfoot>
                    ) : <></>
                    }

                </Table>
            </TableContainer>
            {/* <Table
                size="sm"
                emptyData={{
                    text: "No Data Available."
                }}
                colorScheme="blue"
                totalRegisters={BHKList.length}
                page={page}
                onPageChange={(page) => setPage(page)}
                columns={tableColumns}
                data={tableData}
            /> */}
            <ModalComponent size="3xl" Title="Add/Update FAQ" isOpen={isAddOpen} onOpen={onAddOpen} onClose={onAddClose} onSave={handleSubmit(SaveFAQDetail)} >
                <Stack spacing={4}>
                    <HStack>
                        <Box width={"full"}>
                            <FormControl isRequired isInvalid={errors.FAQ_Type}>
                                <FormLabel>FAQ Type</FormLabel>
                                <Select className="form-control"{...register("FAQ_Type", { required: "FAQ_Type is Mandatory",
                  })}
               >
                  <option value="">Select FAQ Type</option>
                  {CommonVariables.FAQType.map((data, index) => {
                    return (
                      <option
                        value={data.Value}
                        key={index}>
                        {data.Key}
                      </option>
                    );
                  })}
                </Select>
                               <FormErrorMessage>
                                    {errors.FAQ_Type && errors.FAQ_Type.message}
                                </FormErrorMessage>
                            </FormControl>
                        </Box>
                        <Box width={"full"}>
                            <FormControl isRequired isInvalid={errors.FAQ_Title}>
                                <FormLabel>FAQ Title</FormLabel>
                                <Input placeholder='Enter FAQ Title' {...register("FAQ_Title", { required: "Field is Mandatory" })} />
                                <FormErrorMessage>
                                    {errors.FAQ_Title && errors.FAQ_Title.message}
                                </FormErrorMessage>
                            </FormControl>
                        </Box>
                        </HStack>     
                                                         
                        

                       <HStack>
                       <Box width={"full"}>
                            <FormControl isRequired isInvalid={errors.Sequence_No}>
                                <FormLabel>SequenceNo</FormLabel>
                                <Input placeholder='Enter SequenceNo' {...register("Sequence_No", { required: "Field is Mandatory" })} />
                                <FormErrorMessage>
                                    {errors.Sequence_No && errors.Sequence_No.message}
                                </FormErrorMessage>
                            </FormControl>
                        </Box>
                        <Box width={"full"}>
                            <FormControl isRequired isInvalid={errors.Service_Id}>
                                <FormLabel>Select Service Name </FormLabel>
                                <Select className="form-control" {...register("Service_Id", { required: "Field is Mandatory" })} >
                                    <option value="">Select Name</option>
                                    {
                                        servicelist.map((data, index) => {
                                            return (
                                                <option value={data.Id} key={index}>{data.Service_Name}</option>
                                            )
                                        })
                                    }
                                </Select>
                              
                                <FormErrorMessage>
                                    {errors.Service_Id && errors.Service_Id.message}
                                </FormErrorMessage>
                            </FormControl>
                        </Box>
                    </HStack>  
                    <HStack>
                        <Box width={"full"}>
                            <FormControl isRequired >
                                <FormLabel>FAQ Detail</FormLabel>
                                <JoditEditor
                                    ref={servicefaqeditor}
                                    value={servicefaqdetail}
                                    config={shortdescrip_config}
                                    tabIndex={1} // tabIndex of textarea
                                    onBlur={newservicefaqdetailContent => setServiceFAQDetail(newservicefaqdetailContent)} // preferred to use only this option to update the content for performance reasons
                                    onChange={newservicefaqdetailContent => { }}
                                />
                            </FormControl>
                        </Box>
                            </HStack>                                 
                  
                
                   
                </Stack>
            </ModalComponent>
          
           
        </ContainerComponent >
    )
}

export default FAQComponent