import axios from 'axios'
import * as CommonVariables from '../../Common/CommonVariables'
import { trackPromise } from 'react-promise-tracker';                       


//for bhk list
export const GetServicePinCodeMasterList = async () => {
    var result = await trackPromise(axios.get(CommonVariables.API_DOMAIN + `ServicePinCodeMaster/GetServicePinCodeMasterList`, CommonVariables.API_HEADER)).then(response => response);
    return result;
}

//for detail by id
export const GetServicePinCodeDetailsById = async (id) => {
    var result = await trackPromise(axios.get(CommonVariables.API_DOMAIN + `ServicePinCodeMaster/GetServicePinCodeListById?servicepincodeid=${id}`, CommonVariables.API_HEADER)).then(response => response);
    return result;
}

//for saving new bhk detail
export const SaveServicePinCodeDetails = async (data) => {
 
    var result = await trackPromise(axios.post(CommonVariables.API_DOMAIN + `ServicePinCodeMaster/SaveServicePinCodeDetails`,JSON.stringify(data), CommonVariables.API_HEADER)).then(response => response);
    return result;
}

//for updating existing bhk detail
export const UpdateServicePinCodeDetails = async (data) => {
    var result = await trackPromise(axios.post(CommonVariables.API_DOMAIN + `ServicePinCodeMaster/UpdateServicePinCodeDetails`,JSON.stringify(data), CommonVariables.API_HEADER)).then(response => response);
    return result;
}


export const DeleteServicePinCodeById = async (Id) => {
    //debugger;
    const response = await trackPromise(axios.post(CommonVariables.API_DOMAIN + `ServicePinCodeMaster/DeleteServicePinCodeById?servicepincodeid=${Id}`, CommonVariables.API_HEADER))
        .then(response => response);
    return response;
}


