import { BrowserRouter, Routes, Route, Navigate, useNavigate, useLocation } from 'react-router-dom';
import './App.css';
import _LoginLayout from './PageLayout/_LoginLayout';
import _DashboardLayout from './PageLayout/_DashboardLayout';
import LoginPage from './Pages/LoginPage';
import DashboardComponent from './Components/Dashboard/DashboardComponent';
import UserPage from './Pages/UserPage';
import BHKPage from './Pages/BHKPage';
import BrandPage from './Pages/BrandPage'
import BannerPage from './Pages/BannerPage'
import MenuPage from './Pages/MenuPage';
import OfferPage from './Pages/OfferPage';
import Social_MediaPage from './Pages/Social_MediaPage';
import VideoPage from './Pages/Videopage';
import PriceMatrixPage from './Pages/PriceMatrixPage';
import ServicePinCodePage from './Pages/ServicePincodePage';
import ServicePlanPage from './Pages/ServicePlan';
import ServiceMasterPage from './Pages/ServiceMasterPage';
import CampaignMasterPage from './Pages/CampaignMasterPage';
import ServiceFAQMasterPage from './Pages/ServiceFAQPage';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <_LoginLayout>
              <LoginPage></LoginPage>
            </_LoginLayout>
          }
        />
        <Route
          path="/login"
          element={
            <_LoginLayout>
              <LoginPage></LoginPage>
            </_LoginLayout>
          }
        />
        <Route
          path="/dashboard"
          element={
            <_DashboardLayout>
              <DashboardComponent></DashboardComponent>
            </_DashboardLayout>
          }
        />      
      
         <Route
          path="/user"
          element={
            <_DashboardLayout>
              <UserPage />
            </_DashboardLayout>
          }
        />  
        <Route
          path="/bhk"
          element={
            <_DashboardLayout>
              <BHKPage />
            </_DashboardLayout>
          }
        />  
         <Route
          path="/brand"
          element={
            <_DashboardLayout>
              <BrandPage />
            </_DashboardLayout>
          }
        />  
          <Route
          path="/banner"
          element={
            <_DashboardLayout>
              <BannerPage />
            </_DashboardLayout>
          }
        />  
         <Route
          path="/menu"
          element={
            <_DashboardLayout>
              <MenuPage/>
            </_DashboardLayout>
          }
        />  
          <Route
          path="/offer"
          element={
            <_DashboardLayout>
              <OfferPage/>
            </_DashboardLayout>
          }
        />  
          <Route
          path="/social"
          element={
            <_DashboardLayout>
              <Social_MediaPage/>
            </_DashboardLayout>
          }
        />  
         <Route
          path="/video"
          element={
            <_DashboardLayout>
              <VideoPage/>
            </_DashboardLayout>
          }
        />  
        <Route
          path="/matrix"
          element={
            <_DashboardLayout>
              <PriceMatrixPage/>
            </_DashboardLayout>
          }
        />  
          <Route
          path="/pincode"
          element={
            <_DashboardLayout>
              <ServicePinCodePage/>
            </_DashboardLayout>
          }
        />  
         <Route
          path="/serviceplan"
          element={
            <_DashboardLayout>
              <ServicePlanPage/>
            </_DashboardLayout>
          }
        /> 
          <Route
          path="/service"
          element={
            <_DashboardLayout>
              <ServiceMasterPage/>
            </_DashboardLayout>
          }
        /> 
         <Route
          path="/campaignmaster"
          element={
            <_DashboardLayout>
              <CampaignMasterPage/>
            </_DashboardLayout>
          }
        /> 
         <Route
          path="/faq"
          element={
            <_DashboardLayout>
              <ServiceFAQMasterPage/>
            </_DashboardLayout>
          }
        /> 
        
          
          
      </Routes>
    </BrowserRouter>
  );
}

export default App;
