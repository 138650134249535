import { useColorModeValue } from '@chakra-ui/color-mode'
import { Box } from '@chakra-ui/layout'
import React, { useEffect } from 'react'
import { Navigate } from 'react-router-dom'
import LeftMenuComponent from '../Components/Menu/LeftMenuComponent'
import TopMenuComponent from '../Components/Menu/TopMenuComponent'
import { store } from '../Store/store'
import { useDisclosure } from '@chakra-ui/react';

function _DashboardLayout(props) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const userInfo = store.getState();
    const isAuthenticated = userInfo.isAuthenticated;
    if (isAuthenticated ==true) {
        return (
            <Box minH="100vh">
                <LeftMenuComponent isOpen={isOpen} onOpen={onOpen} onClose={onClose}></LeftMenuComponent>
                <TopMenuComponent isOpen={isOpen} onOpen={onOpen} onClose={onClose} />
                <Box ml={{ base: 0, md: 48 }} p="2" backgroundColor={"white"}>
                    {props.children}
                </Box>
            </Box>
        )
    } else {
        return <Navigate to="/login" />
    }
}

export default _DashboardLayout