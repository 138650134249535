//export const API_DOMAIN = "http://localhost:26005/api/";
//export const API_DOMAIN = "https://connect.hicare.in/mobileadminapi/api/";
export const API_DOMAIN ="https://mobileapi.hicare.in/mobileadminapi/api/"


export const API_HEADER = {
    headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
    }
};

export const FILE_UPLOAD_API_HEADER = {
    headers: {
        'content-type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*'
    }
};

export const RegionName = [
    { Key: "East", Value: "East" },
    { Key: "North 1", Value: "North 1" },
    { Key: "North 2", Value: "North 2" },
    { Key: "SC Test", Value: "SC Test" },
    { Key: "South 1", Value: "South 1" },
    { Key: "South 2", Value: "South 2" },
    { Key: "South 3", Value: "South 3" },
    { Key: "West 1", Value: "West 1" },
    { Key: "West 2", Value: "West 2" },
    { Key: "West 3", Value: "West 3" }
];
export const Discount_Type = [
    { Key: "Percentage", Value: "Percentage" },
    { Key: "Cash", Value: "Cash" }
];
export const Campaign_Type = [
    { Key: "Order Value", Value: "Order Value" },
    { Key: "Service Plan", Value: "Service Plan" }
];
export const FAQType = [
    { Key: "CMS", Value: "CMS" },
    { Key: "MMS", Value: "MMS" },
    { Key: "BBMS", Value: "BBMS" },
    { Key: "LMS", Value: "LMS" },
    { Key: "RMS", Value: "RMS" },
    { Key: "TMS", Value: "TMS" },
    { Key: "FMS", Value: "FMS" }
];
