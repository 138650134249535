import React, { useEffect, useState, useRef } from 'react'
import { useForm } from 'react-hook-form'
import * as ServiceMasterService from '../../Services/ServiceMaster/ServiceMasterService'
import toast from 'react-hot-toast';
import ContainerComponent from '../Container/ContainerComponent';
import { VStack, FormControl,HStack, Tooltip,FormLabel, FormErrorMessage, Input, Checkbox, Modal, ModalOverlay, ModalContent, ModalBody, ModalHeader, ModalCloseButton, ModalFooter, useDisclosure, Flex, Stack, Heading, Table, ButtonGroup, Tfoot, Box, TableContainer, TableCaption, Spacer, Button, Thead, Tbody, Td, Tr, Th, Divider } from '@chakra-ui/react'
import { FaCopyright, FaPlus } from "react-icons/fa";
import ModalComponent from '../Modal/ModalComponent';
import { FiLayout } from 'react-icons/fi';
import { AddIcon, HamburgerIcon, EditIcon, DeleteIcon } from '@chakra-ui/icons';
import * as CommonVariables from '../../Common/CommonVariables';
import * as AttachmentService from "../../Services/Attachment/AttachmentService";
import FilePicker from "chakra-ui-file-picker";
import { Textarea } from 'evergreen-ui';
import JoditEditor from "jodit-react";



function ServiceMasterComponent() {
    const { register, setValue, reset, setError, handleSubmit, formState: { errors } } = useForm({ mode: "onBlur" });
    const { isOpen: isAddOpen, onOpen: onAddOpen, onClose: onAddClose } = useDisclosure();
    const [servicemasterlist, setServiceMasterList] = useState([]);
    const [selectedservicemasterid, SetSelectedService] = useState(0);    
    const [page, setPage] = useState(1);
    const [serviceImage, setServiceLogo] = useState('');
    const [thumbnailImage, setServicethumbnail] = useState('');
    const shortdescriptioneditor = useRef('');
    const detaildescriptioneditor = useRef('');
    const [shortdescription, setShortDescription] = useState('');
    const [detaildescription, setDetailDescription] = useState('');
    const shortdescrip_config = { //Short_DetailDescription
        readonly: false, // all options from https://xdsoft.net/jodit/doc/
        height: 300
    }
   
    useEffect(() => {
        LoadServiceMasterList();
    }, [])

       
    const LoadServiceMasterList = () => {
        ServiceMasterService.GetServiceMasterList().then(data => {
            if (data.data.IsSuccess) {
                debugger;
                setServiceMasterList(data.data.Data);
            } else {
                toast.error("Somthing went wrong");
            }
        }).catch(error => {
            toast.error("Somthing went wrong");
        })
    }

 
    const Addservice= () => {
        reset({});   
        reset();     
        SetSelectedService(0);
        onAddOpen();
        setServiceLogo('');
        setServicethumbnail('');
        setDetailDescription('');
        setShortDescription('');

        
    }

    const validateservice = (data) => {
        if( serviceImage == '' || thumbnailImage ==''){
            toast.error("Please upload one image.");
            return false;
          }
        else if(shortdescriptioneditor.current.value == "" || shortdescriptioneditor.current.value == null) {
            toast.error('short Description is mandatory!')
            return false;
        }
        else if(detaildescriptioneditor.current.value == "" || detaildescriptioneditor.current.value == null) {
            toast.error('detail Description is mandatory!')
            return false;
        }
          return true;
    }
   
 
    const SaveServiceMasterDetail = (value) => {
        debugger;
       if(validateservice(value))
       {       
        if (selectedservicemasterid <= 0 ) {
            value.Service_Logo =serviceImage;
            value.Service_Thumbnail =thumbnailImage;
            value.Short_Description=shortdescriptioneditor.current.value;
            value.Detail_Description=detaildescriptioneditor.current.value;
            ServiceMasterService.SaveServiceDetails(value).then(data => {
                if (data.data.IsSuccess) {
                    toast.success("Success.");
                    onAddClose();
                    LoadServiceMasterList();
                } else {
                    toast.error("Somthing went wrong.");
                }
            }).catch(error => {
                toast.error("Somthing went wrong.");
            })
        } else {
            value.Id = selectedservicemasterid;
            value.Service_Logo =serviceImage;
            value.Service_Thumbnail =thumbnailImage;
            ServiceMasterService.UpdateServiceDetails(value).then(data => {
                if (data.data.IsSuccess) {
                    toast.success("Success");
                    onAddClose();
                    LoadServiceMasterList();
                } else {
                    toast.error("Somthing went wrong.");
                }
            }).catch(error => {
                toast.error("Somthing went wrong.");
            })
        }
    }
    else{
        return false;
    }
    }
  
    

    const EditServiceMaster = (id) => {
        SetSelectedService(id);
        reset({});
        ServiceMasterService.GetServiceDetailsById(id).then(data => {
            if (data.data.IsSuccess) {
                reset(data.data.Data);
                setServiceLogo(data.data.Data.Service_Logo);
                setServicethumbnail(data.data.Data.Service_Thumbnail);
                setDetailDescription(data.data.Data.Detail_Description);
                setShortDescription(data.data.Data.Short_Description);
                
                onAddOpen();
            } else {
                toast.error("Somthing went wrong");
            }
        }).catch(error => {
            toast.error("Somthing went wrong");
        })
    }

    const DeleteServiceMaster = (id) => {
        debugger;
        var action = window.confirm('Do you want to delete Service?');
        if (action == true) {
            ServiceMasterService.DeleteServiceById(id).then(data => {
                if (data.data.IsSuccess) {
                    console.log(data.data)
                    toast.success(data.data.ResponseMessage);
                    LoadServiceMasterList();
                }
            }).catch(error => {

            })
        }
    }

    const ServiceLogoFileUploadToS3 = (fileList) => {
        debugger;
        if (fileList != null && fileList.length > 0) {
          var formdata = new FormData();
          formdata.append("Myfile", fileList[0]);
    
          AttachmentService.FileUploadToS3(formdata)
            .then((response) => {
              if (response.data.IsSuccess) {
                toast.success(response.data.ResponseMessage);
                setServiceLogo(response.data.Data);
              } else {
                setServiceLogo("");
                toast.error(response.data.ResponseMessage);
              }
            })
            .catch((error) => {
              toast.error("Something went wrong!");
            });
        } else {
          toast.error("Please choose Image First!");
          setServiceLogo("");
        }
      };
      const ServicethumbnailFileUploadToS3 = (fileList) => {
        debugger;
        if (fileList != null && fileList.length > 0) {
          var formdata = new FormData();
          formdata.append("Myfile", fileList[0]);
    
          AttachmentService.FileUploadToS3(formdata)
            .then((response) => {
              if (response.data.IsSuccess) {
                toast.success(response.data.ResponseMessage);
                setServicethumbnail(response.data.Data);
              } else {
                setServicethumbnail("");
                toast.error(response.data.ResponseMessage);
              }
            })
            .catch((error) => {
              toast.error("Something went wrong!");
            });
        } else {
          toast.error("Please choose Image First!");
          setServicethumbnail("");
        }
      };

  

    return (

        <ContainerComponent Title="Service Master List" Icon={<FiLayout />} ShowDownloadAction="false" ShowAction="true" BtnIcon={<FaPlus />} ButtonTitle="Service" AddNewAction={Addservice}>
            <TableContainer>
                <Table variant='simple'>
                    <Thead>
                        <Tr>
                        <Th>Id</Th>
                            <Th>Service Code</Th>
                            <Th>Service Name</Th> 
                            <Th>Service Thumbnail</Th>    
                            <Th>Is Active</Th>                      
                            <Th>Action</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {servicemasterlist.map((data, index) => {
                            return (<Tr key={index}>
                                <Td>{data.Id}</Td>
                                <Td>{data.Service_Code}</Td>
                                <Td>{data.Service_Name}</Td>
                                <Td><a target='_blank' href={data.Service_Thumbnail}><img height={"40px"} width={"40px"} src={data.Service_Thumbnail}></img></a></Td>
                                                          
                                 <Td>
                                    <Checkbox colorScheme={data.Is_Active ? 'green' : 'red'} defaultChecked isReadOnly>
                                    </Checkbox>
                                </Td> 
                                <Td>
                                <ButtonGroup variant={"outline"} spacing="1">
                <Tooltip label='Edit Service' placement='auto-start'>
                    <Button colorScheme="blue" variant="solid" size={"xs"} onClick={() => EditServiceMaster(data.Id)} >
                        <EditIcon />
                    </Button >
                </Tooltip>
                <Tooltip label='Delete Service' placement='auto-start'>
                    <Button colorScheme="red" variant="solid" size={"xs"} onClick={() => DeleteServiceMaster(data.Id)}>
                        <DeleteIcon />
                    </Button>
                </Tooltip>
              
            </ButtonGroup >
                                </Td>
                            </Tr>)
                        })}
                    </Tbody>
                    {servicemasterlist.length <= 0 ? (
                        <Tfoot>
                            <Tr colSpan="3">
                                <Td>
                                    No Data Available
                                </Td>
                            </Tr>
                        </Tfoot>
                    ) : <></>
                    }

                </Table>
            </TableContainer>
          
            <ModalComponent size="2xl" Title="Add/Update Service Master" isOpen={isAddOpen} onOpen={onAddOpen} onClose={onAddClose} onSave={handleSubmit(SaveServiceMasterDetail)} >
                <Stack spacing={4}>
                    <HStack>
                        <Box width={"full"}>
                            <FormControl isRequired isInvalid={errors.Service_Code}>
                                <FormLabel>Enter Service Code</FormLabel>
                                <Input placeholder='Enter Service Code' {...register("Service_Code", { required: "Field is Mandatory" })} />
                                <FormErrorMessage>
                                    {errors.Service_Code && errors.Service_Code.message}
                                </FormErrorMessage>
                            </FormControl>
                        </Box>
                        <Box width={"full"}>
                            <FormControl isRequired isInvalid={errors.Service_Name}>
                                <FormLabel>Enter Service Name</FormLabel>
                                <Input placeholder='Enter Service Name' {...register("Service_Name", { required: "Field is Mandatory" })} />
                                <FormErrorMessage>
                                    {errors.Service_Name && errors.Service_Name.message}
                                </FormErrorMessage>
                            </FormControl>
                        </Box>
                        </HStack>

                        <HStack>
                        <Box width={"full"}>
              <FormControl
                isRequired
                isInvalid={errors.PopUp_Image}>
                <FormLabel>Service Logo Image Url</FormLabel>

                <FilePicker
                  onFileChange={(fileList) => {
                    ServiceLogoFileUploadToS3(fileList);
                  }}
                  placeholder="Video Image"
                  clearButtonLabel="Clear"
                  multipleFiles={false}
                  accept="application/png"
                  hideClearButton={false}
                />
                <FormErrorMessage>
                  {errors.serviceImage && errors.serviceImage.message}
                </FormErrorMessage>
                <Box width={"50%"}>
            <a href={serviceImage} target="blank"><img src={serviceImage} width='50px' height='50px' /></a>
           </Box>
              </FormControl>
            
           </Box>
</HStack>
<HStack>
              <FormControl
                isRequired
                isInvalid={errors.PopUp_Image}>
                <FormLabel>Service Thumbnail Url</FormLabel>

                <FilePicker
                  onFileChange={(fileList) => {
                    ServicethumbnailFileUploadToS3(fileList);
                  }}
                  placeholder="Image"
                  clearButtonLabel="Clear"
                  multipleFiles={false}
                  accept="application/png"
                  hideClearButton={false}
                />
                <FormErrorMessage>
                  {errors.thumbnailImage && errors.thumbnailImage.message}
                </FormErrorMessage>
                <Box width={"50%"}>
            <a href={thumbnailImage} target="blank"><img src={thumbnailImage} width='50px' height='50px' /></a>
            </Box>
              </FormControl>      
           
          

                       
                    </HStack> 
                    <HStack>
                        
                           <Box width={"full"}>
                            <FormControl isRequired >
                                <FormLabel>Short Description</FormLabel>
                                <JoditEditor
                                    ref={shortdescriptioneditor}
                                    value={shortdescription}
                                    config={shortdescrip_config}
                                    tabIndex={1} // tabIndex of textarea
                                    onBlur={newShortContent => setShortDescription(newShortContent)} // preferred to use only this option to update the content for performance reasons
                                    onChange={newShortContent => { }}
                                />
                            </FormControl>
                        </Box>

{/* 
                    <Box width={"full"}>
                            <FormControl isRequired isInvalid={errors.Short_Description}>
                                <FormLabel>Enter Short Description</FormLabel>
                                <Textarea placeholder='Enter Short_Description' {...register("Short_Description", { required: "Field is Mandatory" })} />
                                <FormErrorMessage>
                                    {errors.Short_Description && errors.Short_Description.message}
                                </FormErrorMessage>
                            </FormControl>
                        </Box> */}
                        </HStack>  
                   <HStack>
                   <Box width={"full"}>
                            <FormControl isRequired >
                                <FormLabel>Detail Description</FormLabel>
                                <JoditEditor
                                    ref={detaildescriptioneditor}
                                    value={detaildescription}
                                    config={shortdescrip_config}
                                    tabIndex={1} // tabIndex of textarea
                                    onBlur={newDetailContent => setDetailDescription(newDetailContent)} // preferred to use only this option to update the content for performance reasons
                                    onChange={newDetailContent => { }}
                                />
                            </FormControl>
                        </Box>
                   {/* <Box width={"full"}>
                            <FormControl isRequired isInvalid={errors.Detail_Description}>
                                <FormLabel>Enter Detail Description</FormLabel>
                                <Textarea placeholder='Enter Detail Description' {...register("Detail_Description", { required: "Field is Mandatory" })} />
                                <FormErrorMessage>
                                    {errors.Detail_Description && errors.Detail_Description.message}
                                </FormErrorMessage>
                            </FormControl>
                        </Box>  */}
                   </HStack>
                   <HStack>
                        <Box width={"full"}>
                            <FormControl isRequired isInvalid={errors.Ratings}>
                                <FormLabel>Enter Rating</FormLabel>
                                <Input placeholder='Enter Rating' {...register("Ratings", { required: "Field is Mandatory" })} />
                                <FormErrorMessage>
                                    {errors.Ratings && errors.Ratings.message}
                                </FormErrorMessage>
                            </FormControl>
                        </Box>
                       
                        </HStack>
                        
                          
                      </Stack>
            </ModalComponent>
          
           
        </ContainerComponent >
    )
}

export default ServiceMasterComponent