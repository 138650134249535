import axios from 'axios'
import * as CommonVariables from '../../Common/CommonVariables'
import { trackPromise } from 'react-promise-tracker';                       


//for bhk list
export const GetServiceMasterList = async () => {
    var result = await trackPromise(axios.get(CommonVariables.API_DOMAIN + `ServiceMaster/GetServiceMasterList`, CommonVariables.API_HEADER)).then(response => response);
    return result;
}

//for detail by id
export const GetServiceDetailsById = async (id) => {
    var result = await trackPromise(axios.get(CommonVariables.API_DOMAIN + `ServiceMaster/GetServiceListById?serviceid=${id}`, CommonVariables.API_HEADER)).then(response => response);
    return result;
}

//for saving new bhk detail
export const SaveServiceDetails = async (data) => {
 
    var result = await trackPromise(axios.post(CommonVariables.API_DOMAIN + `ServiceMaster/SaveServiceDetails`,JSON.stringify(data), CommonVariables.API_HEADER)).then(response => response);
    return result;
}

//for updating existing bhk detail
export const UpdateServiceDetails = async (data) => {
    var result = await trackPromise(axios.post(CommonVariables.API_DOMAIN + `ServiceMaster/UpdateServiceDetails`,JSON.stringify(data), CommonVariables.API_HEADER)).then(response => response);
    return result;
}


export const DeleteServiceById = async (Id) => {
    //debugger;
    const response = await trackPromise(axios.post(CommonVariables.API_DOMAIN + `ServiceMaster/DeleteServiceById?serviceid=${Id}`, CommonVariables.API_HEADER))
        .then(response => response);
    return response;
}


