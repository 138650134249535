import React, { useEffect, useState, useRef } from 'react'
import { useForm } from 'react-hook-form'
import * as SocialMediaService from '../../Services/Social_Media/Social_MediaService'
import toast from 'react-hot-toast';
import ContainerComponent from '../Container/ContainerComponent';
import { VStack, FormControl,HStack, Tooltip,FormLabel, FormErrorMessage, Input, Checkbox, Modal, ModalOverlay, ModalContent, ModalBody, ModalHeader, ModalCloseButton, ModalFooter, useDisclosure, Flex, Stack, Heading, Table, ButtonGroup, Tfoot, Box, TableContainer, TableCaption, Spacer, Button, Thead, Tbody, Td, Tr, Th, Divider } from '@chakra-ui/react'
import { FaCopyright, FaPlus } from "react-icons/fa";
import ModalComponent from '../Modal/ModalComponent';
import { FiLayout } from 'react-icons/fi';
import { AddIcon, HamburgerIcon, EditIcon, DeleteIcon } from '@chakra-ui/icons';
import * as CommonVariables from '../../Common/CommonVariables';
import * as AttachmentService from "../../Services/Attachment/AttachmentService";
import FilePicker from "chakra-ui-file-picker";



function SocialMediaComponent() {
    const { register, setValue, reset, setError, handleSubmit, formState: { errors } } = useForm({ mode: "onBlur" });
    const { isOpen: isAddOpen, onOpen: onAddOpen, onClose: onAddClose } = useDisclosure();
    const [socialmedialist, setSocialMediaMasterList] = useState([]);
    const [selectedsocialmediaid, SetSelectedSocialMedia] = useState(0);    
    const [page, setPage] = useState(1);
    const [socialmediaImage, setSocialMediaImage] = useState('');

    useEffect(() => {
          LoadSocialMediaMasterList();
    }, [])

       
    const LoadSocialMediaMasterList = () => {
        SocialMediaService.GetSocialMediaMasterList().then(data => {
            if (data.data.IsSuccess) {
                debugger;
                setSocialMediaMasterList(data.data.Data);
            } else {
                toast.error("Somthing went wrong");
            }
        }).catch(error => {
            toast.error("Somthing went wrong");
        })
    }

 
    const AddSocialMedia = () => {
        reset({});  
        reset();      
        SetSelectedSocialMedia(0);
        onAddOpen();
        setSocialMediaImage('');

        
    }
    const validateSocialMedia = (data) => {
        if( socialmediaImage == ''){
            toast.error("Please upload one image.");
            return false;
          }
          return true;
    }
 
    const SaveOfferDetail = (value) => {
        debugger;
        if(validateSocialMedia(value))
        {
       
        if (selectedsocialmediaid <= 0 ) {
            value.ImageUrl =socialmediaImage;
            SocialMediaService.SaveSocialMediaDetails(value).then(data => {
                if (data.data.IsSuccess) {
                    toast.success("Success.");
                    onAddClose();
                    LoadSocialMediaMasterList();
                } else {
                    toast.error("Somthing went wrong.");
                }
            }).catch(error => {
                toast.error("Somthing went wrong.");
            })
        } else {
            value.Id = selectedsocialmediaid;
            value.ImageUrl =socialmediaImage;
            SocialMediaService.UpdateSocialMediaDetails(value).then(data => {
                if (data.data.IsSuccess) {
                    toast.success("Success");
                    onAddClose();
                    LoadSocialMediaMasterList();
                } else {
                    toast.error("Somthing went wrong.");
                }
            }).catch(error => {
                toast.error("Somthing went wrong.");
            })
        }
    }
    else{
        return false;
    }
    }

    const EditSocialMediaMaster = (id) => {
        SetSelectedSocialMedia(id);
        reset({});
        SocialMediaService.GetSocialMediaDetailsById(id).then(data => {
            if (data.data.IsSuccess) {
                reset(data.data.Data);
                setSocialMediaImage(data.data.Data.ImageUrl);
                onAddOpen();
            } else {
                toast.error("Somthing went wrong");
            }
        }).catch(error => {
            toast.error("Somthing went wrong");
        })
    }

    const DeleteSocialMedia = (id) => {
        debugger;
        var action = window.confirm('Do you want to delete Data?');
        if (action == true) {
            SocialMediaService.DeleteSocialMediaById(id).then(data => {
                if (data.data.IsSuccess) {
                    console.log(data.data)
                    toast.success(data.data.ResponseMessage);
                    LoadSocialMediaMasterList();
                }
            }).catch(error => {

            })
        }
    }

    const SocialMediaFileUploadToS3 = (fileList) => {
        debugger;
        if (fileList != null && fileList.length > 0) {
          var formdata = new FormData();
          formdata.append("Myfile", fileList[0]);
    
          AttachmentService.FileUploadToS3(formdata)
            .then((response) => {
              if (response.data.IsSuccess) {
                toast.success(response.data.ResponseMessage);
                setSocialMediaImage(response.data.Data);
              } else {
                setSocialMediaImage("");
                toast.error(response.data.ResponseMessage);
              }
            })
            .catch((error) => {
              toast.error("Something went wrong!");
            });
        } else {
          toast.error("Please choose Image First!");
          setSocialMediaImage("");
        }
      };

    return (




        <ContainerComponent Title="Social Media Master List" Icon={<FiLayout />} ShowDownloadAction="false" ShowAction="true" BtnIcon={<FaPlus />} ButtonTitle="Social Media" AddNewAction={AddSocialMedia}>
            <TableContainer>
                <Table variant='simple'>
                    <Thead>
                        <Tr>
                        <Th>Id</Th>
                            <Th>Title</Th>
                            <Th>Image Url</Th>  
                            <Th>Sequence No</Th>    
                            <Th>Is Active</Th>                           
                            <Th>Action</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {socialmedialist.map((data, index) => {
                            return (<Tr key={index}>
                                <Td>{data.Id}</Td>
                                <Td>{data.Title}</Td>
                                <Td><a target='_blank' href={data.ImageUrl}><img height={"40px"} width={"40px"} src={data.ImageUrl}></img></a></Td>
                                <Td>{data.SequenceNo}</Td>
                            
                                <Td>
                                    <Checkbox colorScheme={data.Is_Active ? 'green' : 'red'} defaultChecked isReadOnly>
                                    </Checkbox>
                                </Td> 
                                <Td>
                                <ButtonGroup variant={"outline"} spacing="1">
                <Tooltip label='Edit Social Media' placement='auto-start'>
                    <Button colorScheme="blue" variant="solid" size={"xs"} onClick={() => EditSocialMediaMaster(data.Id)} >
                        <EditIcon />
                    </Button >
                </Tooltip>
                <Tooltip label='Delete Social Media' placement='auto-start'>
                    <Button colorScheme="red" variant="solid" size={"xs"} onClick={() => DeleteSocialMedia(data.Id)}>
                        <DeleteIcon />
                    </Button>
                </Tooltip>
              
            </ButtonGroup >
                                </Td>
                            </Tr>)
                        })}
                    </Tbody>
                    {socialmedialist.length <= 0 ? (
                        <Tfoot>
                            <Tr colSpan="3">
                                <Td>
                                    No Data Available
                                </Td>
                            </Tr>
                        </Tfoot>
                    ) : <></>
                    }

                </Table>
            </TableContainer>
          
            <ModalComponent size="3xl" Title="Add/Update Social Media" isOpen={isAddOpen} onOpen={onAddOpen} onClose={onAddClose} onSave={handleSubmit(SaveOfferDetail)} >
                <Stack spacing={4}>
                    <HStack>
                        <Box width={"full"}>
                            <FormControl isRequired isInvalid={errors.Title}>
                                <FormLabel>Title</FormLabel>
                                <Input placeholder='Enter Title' {...register("Title", { required: "Field is Mandatory" })} />
                                <FormErrorMessage>
                                    {errors.Title && errors.Title.message}
                                </FormErrorMessage>
                            </FormControl>
                        </Box>
                        <Box width={"full"}>
                            <FormControl isRequired isInvalid={errors.Message}>
                                <FormLabel>Enter Message </FormLabel>
                                <Input placeholder='Message' {...register("Message", { required: "Field is Mandatory" })} />
                                <FormErrorMessage>
                                    {errors.Message && errors.Message.message}
                                </FormErrorMessage>
                            </FormControl>
                        </Box>
                      </HStack>
                      
                       <HStack>
                        <Box width={"full"}>
                            <FormControl isRequired isInvalid={errors.SequenceNo}>
                                <FormLabel>Enter Sequence No</FormLabel>
                                <Input placeholder='Enter Sequence No' {...register("SequenceNo", { required: "Field is Mandatory" })} />
                                <FormErrorMessage>
                                    {errors.SequenceNo && errors.SequenceNo.message}
                                </FormErrorMessage>
                            </FormControl>
                        </Box>
                        </HStack>
                        <HStack>
                        <Box width={"full"}>
                            <FormControl isRequired isInvalid={errors.PageUrl}>
                                <FormLabel>Enter Page Url</FormLabel>
                                <Input placeholder='Enter PageUrl' {...register("PageUrl", { required: "Field is Mandatory" })} />
                                <FormErrorMessage>
                                    {errors.PageUrl && errors.PageUrl.message}
                                </FormErrorMessage>
                            </FormControl>
                        </Box>
                        </HStack>

                      <HStack>
                        <Box width={"full"}>
              <FormControl
                isRequired
                isInvalid={errors.PopUp_Image}>
                <FormLabel>Social Media Image Url</FormLabel>

                <FilePicker
                  onFileChange={(fileList) => {
                    SocialMediaFileUploadToS3(fileList);
                  }}
                  placeholder="Social Media Image"
                  clearButtonLabel="Clear"
                  multipleFiles={false}
                  accept="application/png"
                  hideClearButton={false}
                />
                <FormErrorMessage>
                  {errors.socialmediaImage && errors.socialmediaImage.message}
                </FormErrorMessage>
              </FormControl>
           
            </Box>
            <Box width={"50%"}>
            <a href={socialmediaImage} target="blank"><img src={socialmediaImage} width='50px' height='50px' /></a>
            </Box>

                       
                    </HStack>    
                    <HStack>
                        <Box width={"full"}>
                            {
                                selectedsocialmediaid > 0 ? (
                                    <FormControl>
                                        <FormLabel>Is Active</FormLabel>
                                        <Checkbox colorScheme='green'  {...register("Is_Active",)}>
                                        </Checkbox>
                                        <FormErrorMessage>
                                        </FormErrorMessage>
                                    </FormControl>
                                ) : <></>
                            }
                        </Box>
                    </HStack>                             
                  
                
                   
                </Stack>
            </ModalComponent>
          
           
        </ContainerComponent >
    )
}

export default SocialMediaComponent