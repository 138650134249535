import React, { useEffect, useState, useRef } from 'react'
import { useForm } from 'react-hook-form'
import * as ServicePlanService from '../../Services/ServicePlan/ServicePlanService'
import toast from 'react-hot-toast';
import ContainerComponent from '../Container/ContainerComponent';
import { VStack, FormControl,HStack,Select, Tooltip,FormLabel, FormErrorMessage, Input, Checkbox, Modal, ModalOverlay, ModalContent, ModalBody, ModalHeader, ModalCloseButton, ModalFooter, useDisclosure, Flex, Stack, Heading, Table, ButtonGroup, Tfoot, Box, TableContainer, TableCaption, Spacer, Button, Thead, Tbody, Td, Tr, Th, Divider } from '@chakra-ui/react'
import { FaCopyright, FaPlus } from "react-icons/fa";
import ModalComponent from '../Modal/ModalComponent';
import { FiLayout } from 'react-icons/fi';
import { AddIcon, HamburgerIcon, EditIcon, DeleteIcon } from '@chakra-ui/icons';
import * as CommonVariables from '../../Common/CommonVariables';
import * as AttachmentService from "../../Services/Attachment/AttachmentService";
import FilePicker from "chakra-ui-file-picker";
import JoditEditor from "jodit-react";
import ReactDatePicker from "react-datepicker";
import * as moment from "moment";


function ServicePlanComponent() {
    const { register, setValue, reset, setError, handleSubmit, formState: { errors } } = useForm({ mode: "onBlur" });
    const { isOpen: isAddOpen, onOpen: onAddOpen, onClose: onAddClose } = useDisclosure();
    const [serviceplanlist, setServicePlanMasterList] = useState([]);
    const [selectedserviceplanid, SetSelecteServicePlan] = useState(0);    
    const [page, setPage] = useState(1);
    const serviceplaneditor = useRef('');
    const serviceinstructioneditor = useRef('');
    const [serviceplandescription, setServiceplanDescription] = useState('');
    const [serviceinstructiondescription, setServiceinstructionDescription] = useState('');
    const shortdescrip_config = { //Short_DetailDescription
        readonly: false, // all options from https://xdsoft.net/jodit/doc/
        height: 300
    };
    const [Discount_Start_Date, setStartDate] = useState(new Date());
    const [Discount_End_Date, setEndDate] = useState(new Date());
    

    useEffect(() => {
          LoadServicePlanMasterList();
    }, [])

       
    const LoadServicePlanMasterList = () => {
        ServicePlanService.GetServicePlanMasterList().then(data => {
            if (data.data.IsSuccess) {
                debugger;
                setServicePlanMasterList(data.data.Data);
            } else {
                toast.error("Somthing went wrong");
            }
        }).catch(error => {
            toast.error("Somthing went wrong");
        })
    }

 
    const Addserviceplan = () => {
        reset({});      
        reset();  
        SetSelecteServicePlan(0);
        onAddOpen();
        setServiceplanDescription('');
        setServiceinstructionDescription('');
        setStartDate(new Date());
        setEndDate(new Date());
    }
    let handleColor = (time) => {
        return time.getHours() > 12 ? "text-success" : "text-error";
      };
      function clickHandler(stDate, endDate) {
        const startdate = new Date(stDate);
        setStartDate(startdate);
        const edDate = new Date(endDate);
        setEndDate(edDate);
      }
   
 
    const SaveServicePlanDetail = (value) => {
        debugger;
        if (serviceplaneditor.current.value == "" || serviceplaneditor.current.value == null) {
            toast.error('Service Plan Description is mandatory!')
            return false;
        }
        else if (serviceinstructioneditor.current.value == "" || serviceinstructioneditor.current.value == null) {
            toast.error('Service Instructor Description is mandatory!')
            return false;
        }
       
    else if (selectedserviceplanid <= 0 ) {
        value.Service_Plan_Description = serviceplaneditor.current.value;
        value.Service_Instructions = serviceinstructioneditor.current.value;
            ServicePlanService.SaveServicePlanDetails(value).then(data => {

                if (data.data.IsSuccess) {
                    toast.success("Success.");
                    onAddClose();
                    LoadServicePlanMasterList();
                } else {
                    toast.error("Somthing went wrong.");
                }
            }).catch(error => {
                toast.error("Somthing went wrong.");
            })
        } else {
            value.Id = selectedserviceplanid;
             ServicePlanService.UpdateServicePlanDetails(value).then(data => {
                if (data.data.IsSuccess) {
                    toast.success("Success");
                    onAddClose();
                    LoadServicePlanMasterList();
                } else {
                    toast.error("Somthing went wrong.");
                }
            }).catch(error => {
                toast.error("Somthing went wrong.");
            })
        }
    }
  
    

    const EditServicePlanMaster = (id) => {
        SetSelecteServicePlan(id);
        reset({});
        ServicePlanService.GetServicePlanDetailsById(id).then(data => {
            if (data.data.IsSuccess) {
                reset(data.data.Data);
                 onAddOpen();
                 setServiceplanDescription(data.data.Data.Service_Plan_Description);
                 setServiceinstructionDescription(data.data.Data.Service_Instructions)
            } else {
                toast.error("Somthing went wrong");
            }
        }).catch(error => {
            toast.error("Somthing went wrong");
        })
    }

    const DeleteServicePlan = (id) => {
        debugger;
        var action = window.confirm('Do you want to delete Serviceplan?');
        if (action == true) {
            ServicePlanService.DeleteServicePlanById(id).then(data => {
                if (data.data.IsSuccess) {
                    console.log(data.data)
                    toast.success(data.data.ResponseMessage);
                    LoadServicePlanMasterList();
                }
            }).catch(error => {

            })
        }
    }

   
    return (




        <ContainerComponent Title="ServicePlan Master List" Icon={<FiLayout />} ShowDownloadAction="false" ShowAction="true" BtnIcon={<FaPlus />} ButtonTitle="ServicePlan" AddNewAction={Addserviceplan}>
            <TableContainer>
                <Table variant='simple'>
                    <Thead>
                        <Tr>
                        <Th>Id</Th>
                            <Th>SpCode</Th>
                            <Th>Service Plan Name</Th>     
                            <Th>Is Active</Th>                       
                            <Th>Action</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {serviceplanlist.map((data, index) => {
                            return (<Tr key={index}>
                                <Td>{data.Id}</Td>
                                <Td>{data.SPCode}</Td>
                                <Td>{data.Service_Plan_Name}</Td>
                            
                             <Td>
                                    <Checkbox colorScheme={data.Is_Active ? 'green' : 'red'} defaultChecked isReadOnly>
                                    </Checkbox>
                                </Td>
                                <Td>
                                <ButtonGroup variant={"outline"} spacing="1">
                <Tooltip label='Edit Serviceplan' placement='auto-start'>
                    <Button colorScheme="blue" variant="solid" size={"xs"} onClick={() => EditServicePlanMaster(data.Id)} >
                        <EditIcon />
                    </Button >
                </Tooltip>
                <Tooltip label='Delete ServicePlan' placement='auto-start'>
                    <Button colorScheme="red" variant="solid" size={"xs"} onClick={() => DeleteServicePlan(data.Id)}>
                        <DeleteIcon />
                    </Button>
                </Tooltip>
              
            </ButtonGroup >
                                </Td>
                            </Tr>)
                        })}
                    </Tbody>
                    {serviceplanlist.length <= 0 ? (
                        <Tfoot>
                            <Tr colSpan="3">
                                <Td>
                                    No Data Available
                                </Td>
                            </Tr>
                        </Tfoot>
                    ) : <></>
                    }

                </Table>
            </TableContainer>
          
            <ModalComponent size="2xl" Title="Add/Update ServicePlan" isOpen={isAddOpen} onOpen={onAddOpen} onClose={onAddClose} onSave={handleSubmit(SaveServicePlanDetail)} >
                <Stack spacing={4}>
                    <HStack>
                        <Box width={"full"}>
                            <FormControl isRequired isInvalid={errors.SPCode}>
                                <FormLabel>Service Plan Code</FormLabel>
                                <Input placeholder='Enter SPCode ' {...register("SPCode", { required: "Field is Mandatory" })} />
                                <FormErrorMessage>
                                    {errors.SPCode && errors.SPCode.message}
                                </FormErrorMessage>
                            </FormControl>
                        </Box>
                        <Box width={"full"}>
                            <FormControl isRequired isInvalid={errors.Service_Plan_Name}>
                                <FormLabel>Service Plan Name </FormLabel>
                                <Input placeholder='Enter Service Plan Name' {...register("Service_Plan_Name", { required: "Field is Mandatory" })} />
                                <FormErrorMessage>
                                    {errors.Service_Plan_Name && errors.Service_Plan_Name.message}
                                </FormErrorMessage>
                            </FormControl>
                        </Box>
                      </HStack>
                      <HStack>
                      <Box width={"full"}>
                            <FormControl isRequired isInvalid={errors.Plan_MRP}>
                                <FormLabel>Plan MRP </FormLabel>
                                <Input placeholder='Enter Plan MRP' {...register("Plan_MRP", { required: "Field is Mandatory" })} />
                                <FormErrorMessage>
                                    {errors.Plan_MRP && errors.Plan_MRP.message}
                                </FormErrorMessage>
                            </FormControl>
                        </Box>
                       
                        <Box width={"full"}>
                            <FormControl isRequired isInvalid={errors.City_Name}>
                                <FormLabel>City Name</FormLabel>
                                <Input placeholder='Enter City Name' {...register("City_Name", { required: "Field is Mandatory" })} />
                                <FormErrorMessage>
                                    {errors.City_Name && errors.City_Name.message}
                                </FormErrorMessage>
                            </FormControl>
                        </Box>
                        </HStack>  
                        <HStack>
                        <Box width={400}>
                        <FormControl isRequired isInvalid={errors.Discount_Type}>
                                <FormLabel>Discount Type</FormLabel>
                                <Select className="form-control" {...register("Discount_Type")} >
                                    <option value="">Select Discount Type</option>
                                    {
                                        CommonVariables.Discount_Type.map((data, index) => {
                                            return (
                                                <option value={data.Value} key={index}>{data.Key}</option>
                                            )
                                        })
                                    }
                                </Select>
                                <FormErrorMessage>
                                    {errors.Discount_Type && errors.Discount_Type.message}
                                </FormErrorMessage>
                            </FormControl>
                        </Box>
                        <Box width={"full"}>
                            <FormControl isRequired isInvalid={errors.Discount}>
                                <FormLabel>Discount</FormLabel>
                                <Input placeholder='Enter Discount' {...register("Discount", { required: "Field is Mandatory",setValueAs: (v) => parseInt(v), })} />
                                <FormErrorMessage>
                                    {errors.Discount && errors.Discount.message}
                                </FormErrorMessage>
                            </FormControl>
                        </Box>
                        </HStack>  
                        <HStack>
                        <Box width={"full"}>
                            <FormControl isRequired isInvalid={errors.Ratings}>
                                <FormLabel>Ratings</FormLabel>
                                <Input placeholder='Enter Ratings' {...register("Ratings", { required: "Field is Mandatory",setValueAs: (v) => parseInt(v), })} />
                                <FormErrorMessage>
                                    {errors.Ratings && errors.Ratings.message}
                                </FormErrorMessage>
                            </FormControl>
                        </Box>

                            </HStack> 
                        <HStack>
            <Box width={"full"}>
              <FormControl isRequired>
                <FormLabel>Discount StartDate</FormLabel>

                <ReactDatePicker
                  valueName="selected" // DateSelect value's name is selected
                  selected={Discount_Start_Date}
                  onChange={(date) => setStartDate(date)}
                  name="ReactDatepicker"
                  showTimeSelect
                  timeClassName={handleColor}
                  className="form-control"
                  placeholderText="Select Start date"
                  dateFormat="dd-MM-yyyy hh:mm aa"
                  value={Discount_Start_Date}
                />
              </FormControl>
            </Box>
            <Box width={"full"}>
              <FormControl isRequired>
                <FormLabel>Discount EndDate</FormLabel>
                <ReactDatePicker
                  valueName="selected" // DateSelect value's name is selected
                  selected={Discount_End_Date}
                  onChange={(date) => setEndDate(date)}
                  name="ReactDatepicker"
                  showTimeSelect
                  timeClassName={handleColor}
                  className="form-control"
                  placeholderText="Select End date"
                  dateFormat="dd-MM-yyyy hh:mm aa"
                  value={Discount_End_Date}
                />
              </FormControl>
            </Box>
            </HStack>
                   <HStack>
                        <Box width={"full"}>
                            <FormControl isRequired >
                                <FormLabel>Service Plan Description</FormLabel>
                                <JoditEditor
                                    ref={serviceplaneditor}
                                    value={serviceplandescription}
                                    config={shortdescrip_config}
                                    tabIndex={1} // tabIndex of textarea
                                    onBlur={newServiceplanContent => setServiceplanDescription(newServiceplanContent)} // preferred to use only this option to update the content for performance reasons
                                    onChange={newServiceplanContent => { }}
                                />
                            </FormControl>
                        </Box>
                        </HStack>
                        <HStack>
                        <Box width={"full"}>
                            <FormControl isRequired >
                                <FormLabel>Service Instructions</FormLabel>
                                <JoditEditor
                                    ref={serviceinstructioneditor}
                                    value={serviceinstructiondescription}
                                    config={shortdescrip_config}
                                    tabIndex={1} // tabIndex of textarea
                                    onBlur={newDetailContent => setServiceinstructionDescription(newDetailContent)} // preferred to use only this option to update the content for performance reasons
                                    onChange={newDetailContent => { }}
                                />
                            </FormControl>
                        </Box>
                        </HStack>   
                        <HStack>
                        <Box width={"full"}>
                            {
                                selectedserviceplanid > 0 ? (
                                    <FormControl>
                                        <FormLabel>Is Active</FormLabel>
                                        <Checkbox colorScheme='green'  {...register("Is_Active",)}>
                                        </Checkbox>
                                        <FormErrorMessage>
                                        </FormErrorMessage>
                                    </FormControl>
                                ) : <></>
                            }
                        </Box>
                    </HStack>                        
                     </Stack>
            </ModalComponent>
          
           
        </ContainerComponent >
    )
}

export default ServicePlanComponent