import axios from 'axios'
import * as CommonVariables from '../../Common/CommonVariables'
import { trackPromise } from 'react-promise-tracker';                       


//for bhk list
export const GetSocialMediaMasterList = async () => {
    var result = await trackPromise(axios.get(CommonVariables.API_DOMAIN + `SocialMedia/GetSocialMediaMasterList`, CommonVariables.API_HEADER)).then(response => response);
    return result;
}

//for detail by id
export const GetSocialMediaDetailsById = async (id) => {
    var result = await trackPromise(axios.get(CommonVariables.API_DOMAIN + `SocialMedia/GetSocialMediaListById?SocialMediaid=${id}`, CommonVariables.API_HEADER)).then(response => response);
    return result;
}

//for saving new bhk detail
export const SaveSocialMediaDetails = async (data) => {
 
    var result = await trackPromise(axios.post(CommonVariables.API_DOMAIN + `SocialMedia/SaveSocialMediaDetails`,JSON.stringify(data), CommonVariables.API_HEADER)).then(response => response);
    return result;
}

//for updating existing bhk detail
export const UpdateSocialMediaDetails = async (data) => {
    var result = await trackPromise(axios.post(CommonVariables.API_DOMAIN + `SocialMedia/UpdateSocialMediaDetails`,JSON.stringify(data), CommonVariables.API_HEADER)).then(response => response);
    return result;
}


export const DeleteSocialMediaById = async (Id) => {
    //debugger;
    const response = await trackPromise(axios.post(CommonVariables.API_DOMAIN + `SocialMedia/DeleteSocialMediaById?SocialMediaid=${Id}`, CommonVariables.API_HEADER))
        .then(response => response);
    return response;
}


