import React from 'react';
import {
    Box,
    CloseButton,
    Flex,
    Icon,
    useColorModeValue,
    Link,
    Drawer,
    DrawerContent,
    Text,
    useDisclosure,
} from '@chakra-ui/react';
import {
    FiHome,
    FiTag,
    FiTruck,
    FiBox,
    FiMapPin,
    FiGrid,
    FiLayout,
    FiDatabase,
    FiShoppingCart,
    FiFileText,
    FiAirplay,
    FiAlertCircle,
    FiUser,
    FiLink,FiCreditCard,FiGift,FiRadio,FiFilm,FiTool,FiHexagon,FiServer
} from 'react-icons/fi';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { store } from '../../Store/store';
import { FaMicroblog } from 'react-icons/fa';
import { BoxIcon, HomeIcon, UserIcon } from 'evergreen-ui';


export default function LeftMenuComponent(props) {
    const navigate = useNavigate();

    return (
        <>
            <SidebarContent
                onClose={() => props.onClose()}
                display={{ base: 'none', md: 'block' }}
            />
            <Drawer
                autoFocus={false}
                isOpen={props.isOpen}
                placement="left"
                onClose={props.onClose()}
                returnFocusOnClose={false}
                onOverlayClick={props.onClose()}
                size="full">
                <DrawerContent>
                    <SidebarContent onClose={() => props.onClose()} />
                </DrawerContent>
            </Drawer>
        </>
    )
}

const SidebarContent = ({ onClose, ...rest }) => {
    let LinkItems = [];
    const userInfo = store.getState();
    debugger;
    if (userInfo.userData.Role_Name == "Admin") {
        
        LinkItems = [
            { name: 'Home', icon: FiHome, url: '/dashboard' },
            { name: 'User Master', icon: UserIcon, url: '/user' },
            { name: 'BHK Master', icon : FiCreditCard , url: '/bhk' },
            { name: 'Brand Master', icon : FiHexagon , url: '/brand' },
            { name: 'Banner Master', icon : FiLayout, url: '/banner' },
            { name: 'Menu Master', icon : FiServer , url: '/menu' },
            { name: 'Offer Master', icon : FiGift , url: '/offer' },
            { name: 'Social Media', icon : FiRadio , url: '/social' },
            { name: 'Video Master', icon : FiFilm , url: '/video' },
            { name: 'Matrix Master', icon : BoxIcon, url: '/matrix' },
            { name: 'Service PinCode ', icon : FiMapPin, url: '/pincode' },
            { name: 'Service Plan ', icon : FiTruck, url: '/serviceplan' },
            { name: 'Service Master ', icon : FiTool , url: '/service' },
            { name: 'Campaign Master ', icon : FiAlertCircle , url: '/campaignmaster' },
            { name: 'FAQ Master ', icon : FiGrid , url: '/faq' },
            
        ];
    }
    else if (userInfo.userData.Role_Name == "Manager") {
        
        LinkItems = [
            { name: 'Home', icon: FiHome, url: '/dashboard' },
            { name: 'User Master', icon: UserIcon, url: '/user' },
            { name: 'BHK Master', icon : HomeIcon, url: '/bhk' },
            { name: 'Brand Master', icon : BoxIcon, url: '/brand' },
            { name: 'Banner Master', icon : BoxIcon, url: '/banner' },
            { name: 'Menu Master', icon : BoxIcon, url: '/menu' },
            { name: 'Offer Master', icon : BoxIcon, url: '/offer' },
            { name: 'Social Media', icon : BoxIcon, url: '/social' },
            { name: 'Video Master', icon : BoxIcon, url: '/video' },
            { name: 'Price Matrix Master', icon : BoxIcon, url: '/matrix' },
        ];
    }
  
    else {
        
        LinkItems = [
            { name: 'Home', icon: FiHome, url: '/dashboard' },
            { name: 'User Master', icon: FiLayout, url: '/user' },
            
        ];
    }

    return (
        <Box
            bg={useColorModeValue('white', 'gray.900')}
            borderRight="1px"
            borderRightColor={useColorModeValue('gray.200', 'gray.700')}
            w={{ base: 'full', md: 48 }}
            pos="fixed"
            h="full"
            {...rest}>
            <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
                <Text fontSize="2xl" fontFamily="monospace" fontWeight="bold">
                    <img src='https://hicare.in/media/logo/stores/1/hicare-logo.png' style={{ height: "58px" }} />
                </Text>
                <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
            </Flex>
            {LinkItems.map((link) => (
                <NavItem key={link.name} icon={link.icon} url={link.url}>
                    {link.name}
                </NavItem>
            ))}
        </Box>
    );
};

const NavItem = ({ icon, children, url, ...rest }) => {
    return (
        <Link  as={RouterLink} to={url} style={{ textDecoration: 'none' }} _focus={{ boxShadow: 'none' }}>
            <Flex
                align="center"
                p="3"
                mx="3"
                borderRadius="md"
                role="group"
                cursor="pointer"
                _hover={{
                    bg: 'cyan.400',
                    color: 'white',
                }}
                padding="5px"
                {...rest}>
                {icon && (
                    <Icon
                        mr="4"
                        fontSize="14"
                        _groupHover={{
                            color: 'white',
                        }}
                        as={icon}
                    />
                )}
                {children}
            </Flex>
        </Link>
    );
};
