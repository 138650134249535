import React, { useEffect, useState, useRef } from 'react'
import { useForm } from 'react-hook-form'
import * as MenuService from '../../Services/Menu/MenuService';
import toast from 'react-hot-toast';
import ContainerComponent from '../Container/ContainerComponent';
import { VStack, FormControl,HStack, Tooltip,FormLabel, FormErrorMessage, Input, Checkbox, Modal, ModalOverlay, ModalContent, ModalBody, ModalHeader, ModalCloseButton, ModalFooter, useDisclosure, Flex, Stack, Heading, Table, ButtonGroup, Tfoot, Box, TableContainer, TableCaption, Spacer, Button, Thead, Tbody, Td, Tr, Th, Divider,Textarea } from '@chakra-ui/react'
import { FaCopyright, FaPlus } from "react-icons/fa";
import ModalComponent from '../Modal/ModalComponent';
import { FiLayout } from 'react-icons/fi';
import { AddIcon, HamburgerIcon, EditIcon, DeleteIcon } from '@chakra-ui/icons';
import * as CommonVariables from '../../Common/CommonVariables';
import * as AttachmentService from "../../Services/Attachment/AttachmentService";
import FilePicker from "chakra-ui-file-picker";


function MenuComponent() {
    const { register, setValue, reset, setError, handleSubmit, formState: { errors } } = useForm({ mode: "onBlur" });
    const { isOpen: isAddOpen, onOpen: onAddOpen, onClose: onAddClose } = useDisclosure();
    const [menulist, setMenuMasterList] = useState([]);
    const [selectedmenuid, SetSelectedMenu] = useState(0);    
    const [page, setPage] = useState(1);
    const [menuImage, setMenuImage] = useState('');

    useEffect(() => {
          LoadMenuMasterList();
    }, [])

       
    const LoadMenuMasterList = () => {
        MenuService.GetMenuMasterList().then(data => {
            if (data.data.IsSuccess) {
                debugger;
                setMenuMasterList(data.data.Data);
            } else {
                toast.error("Somthing went wrong");
            }
        }).catch(error => {
            toast.error("Somthing went wrong");
        })
    }

 
    const AddMenu = () => {
        reset({});      
        reset();  
        SetSelectedMenu(0);
        onAddOpen();
        setMenuImage('');
    }

    const validateMenu = (data) => {
        if( menuImage == ''){
            toast.error("Please upload one image.");
            return false;
          }
          return true;
    }
 
    const SaveMenuDetail = (value) => {
      if(validateMenu(value))
      {      
        if (selectedmenuid <= 0) {
            value.ImageUrl =menuImage;
            MenuService.SaveMenuDetails(value).then(data => {
                if (data.data.IsSuccess) {
                    toast.success("Success.");
                    onAddClose();
                    LoadMenuMasterList();
                } else {
                    toast.error("Somthing went wrong.");
                }
            }).catch(error => {
                toast.error("Somthing went wrong.");
            })
        } else {
            value.Id = selectedmenuid;
            MenuService.UpdateMenuDetails(value).then(data => {
                if (data.data.IsSuccess) {
                    toast.success("Success");
                    onAddClose();
                    LoadMenuMasterList();
                } else {
                    toast.error("Somthing went wrong.");
                }
            }).catch(error => {
                toast.error("Somthing went wrong.");
            })
        }
    }
    else{
        return false;
        toast.error("Somthing went wrong.");
    }
    }

    const EditMenuMaster = (id) => {
        SetSelectedMenu(id);
        reset({});
        MenuService.GetMenuDetailsById(id).then(data => {
            if (data.data.IsSuccess) {
                reset(data.data.Data);
                setMenuImage(data.data.Data.ImageUrl);
                onAddOpen();
            } else {
                toast.error("Somthing went wrong");
            }
        }).catch(error => {
            toast.error("Somthing went wrong");
        })
    }
 
    const DeleteMenu = (id) => {
        debugger;
        var action = window.confirm('Do you want to delete Menu?');
        if (action == true) {
            MenuService.DeleteMenuById(id).then(data => {
                if (data.data.IsSuccess) {
                    console.log(data.data)
                    toast.success(data.data.ResponseMessage);
                    LoadMenuMasterList();
                }
            }).catch(error => {

            })
        }
    }
    const MenuFileUploadToS3 = (fileList) => {
        debugger;
        if (fileList != null && fileList.length > 0) {
          var formdata = new FormData();
          formdata.append("Myfile", fileList[0]);
    
          AttachmentService.FileUploadToS3(formdata)
            .then((response) => {
              if (response.data.IsSuccess) {
                toast.success(response.data.ResponseMessage);
                setMenuImage(response.data.Data);
              } else {
                setMenuImage("");
                toast.error(response.data.ResponseMessage);
              }
            })
            .catch((error) => {
              toast.error("Something went wrong!");
            });
        } else {
          toast.error("Please choose Image First!");
          setMenuImage("");
        }
      };

    return (




        <ContainerComponent Title="Menu Master List" Icon={<FiLayout />} ShowDownloadAction="false" ShowAction="true" BtnIcon={<FaPlus />} ButtonTitle="Menu" AddNewAction={AddMenu}>
            <TableContainer>
                <Table variant='simple'>
                    <Thead>
                        <Tr>
                        <Th>Id</Th>
                            <Th>Menu Title</Th>
                            <Th>Image Url</Th>   
                            <Th>Page Link</Th>  
                         <Th>Is Active</Th>  
                            <Th>Action</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {menulist.map((data, index) => {
                            return (<Tr key={index}>
                                <Td>{data.Id}</Td>
                                <Td>{data.Title}</Td>
                                <Td><a target='_blank' href={data.ImageUrl}><img height={"40px"} width={"40px"} src={data.ImageUrl}></img></a></Td>
                                <Td><a target='_blank' href={data.PageLink}>{data.PageLink}</a></Td>
                            
                               
                                { <Td>
                                    <Checkbox colorScheme={data.Is_Active ? 'green' : 'red'} defaultChecked isReadOnly>
                                    </Checkbox>
                                </Td> }
                                <Td>
                                <ButtonGroup variant={"outline"} spacing="1">
                <Tooltip label='Edit Menu' placement='auto-start'>
                    <Button colorScheme="blue" variant="solid" size={"xs"} onClick={() => EditMenuMaster(data.Id)} >
                        <EditIcon />
                    </Button >
                </Tooltip>
                <Tooltip label='Delete Menu' placement='auto-start'>
                    <Button colorScheme="red" variant="solid" size={"xs"} onClick={() => DeleteMenu(data.Id)}>
                        <DeleteIcon />
                    </Button>
                </Tooltip>
              
            </ButtonGroup >
                                </Td>
                            </Tr>)
                        })}
                    </Tbody>
                    {menulist.length <= 0 ? (
                        <Tfoot>
                            <Tr colSpan="3">
                                <Td>
                                    No Data Available
                                </Td>
                            </Tr>
                        </Tfoot>
                    ) : <></>
                    }

                </Table>
            </TableContainer>
          
            <ModalComponent size="4xl" Title="Add/Update Menu" isOpen={isAddOpen} onOpen={onAddOpen} onClose={onAddClose} onSave={handleSubmit(SaveMenuDetail)} >
                <Stack spacing={4}>
                    <HStack>
                        <Box width={"full"}>
                            <FormControl isRequired isInvalid={errors.Title}>
                                <FormLabel>Menu Title</FormLabel>
                                <Input placeholder='Enter Menu Title' {...register("Title", { required: "Field is Mandatory" })} />
                                <FormErrorMessage>
                                    {errors.Title && errors.Title.message}
                                </FormErrorMessage>
                            </FormControl>
                        </Box>
                       
</HStack>
<HStack>
                        <Box width={"full"}>
              <FormControl
                isRequired
                isInvalid={errors.PopUp_Image}>
                <FormLabel>Menu Image Url</FormLabel>

                <FilePicker
                  onFileChange={(fileList) => {
                    MenuFileUploadToS3(fileList);
                  }}
                  placeholder="Menu Image"
                  clearButtonLabel="Clear"
                  multipleFiles={false}
                  accept="application/png"
                  hideClearButton={false}
                />
                <FormErrorMessage>
                  {errors.menuImage && errors.menuImage.message}
                </FormErrorMessage>
              </FormControl>
           
            </Box>
            <Box width={"50%"}>
            <a href={menuImage} target="blank"><img src={menuImage} width='50px' height='50px' /></a>
            </Box>

                        </HStack>
                        <HStack>
                        <Box width={"full"}>
                            <FormControl isRequired isInvalid={errors.PageLink}>
                                <FormLabel>Page Link</FormLabel>
                                <Input placeholder='Enter PageLink' {...register("PageLink", { required: "Field is Mandatory" })} />
                                <FormErrorMessage>
                                    {errors.PageLink && errors.PageLink.message}
                                </FormErrorMessage>
                            </FormControl>
                        </Box>
                        </HStack>
                        <HStack>
                                          
                        <Box width={"full"}>                          
                               
                            <FormControl>
                                <FormLabel>Is AppLink</FormLabel>
                                <Checkbox  colorScheme='green'  {...register("IsAppLink")}>
                                </Checkbox>
                                <FormErrorMessage>
                                </FormErrorMessage>
                            </FormControl>
                     </Box>
                     </HStack>
                     <HStack>
                     <Box width={"full"}>                          
                               
                               <FormControl>
                                   <FormLabel>Is InApp Browser Link</FormLabel>
                                   <Checkbox colorScheme='green'  {...register("IsInAppBrowserLink")}>
                                   </Checkbox>
                                   <FormErrorMessage>
                                   </FormErrorMessage>
                               </FormControl>
                        </Box>
                        <Box width={"full"}>                          
                               
                               <FormControl>
                                   <FormLabel>Is External AppBrowserLink</FormLabel>
                                   <Checkbox colorScheme='green'   {...register("IsExternalAppBrowserLink")}>
                                   </Checkbox>
                                   <FormErrorMessage>
                                   </FormErrorMessage>
                               </FormControl>
                        </Box>
                        <Box width={"full"}>
                            <FormControl isRequired isInvalid={errors.SequenceNo}>
                                <FormLabel>Enter Sequence No</FormLabel>
                                <Input placeholder='Enter Sequence No' {...register("SequenceNo", { required: "Field is Mandatory" })} />
                                <FormErrorMessage>
                                    {errors.SequenceNo && errors.SequenceNo.message}
                                </FormErrorMessage>
                            </FormControl>
                        </Box>                        
                    </HStack>  
                    <HStack>
                    <Box width={"full"}>                          
                               
                               <FormControl>
                                   <FormLabel>Is Icon</FormLabel>
                                   <Checkbox colorScheme='green'   {...register("IsIcon")}>
                                   </Checkbox>
                                   <FormErrorMessage>
                                   </FormErrorMessage>
                               </FormControl>
                        </Box>
                        </HStack>   

                        <HStack>
                        <Box width={"full"}>
                            {
                                selectedmenuid > 0 ? (
                                    <FormControl>
                                        <FormLabel>Is Active</FormLabel>
                                        <Checkbox colorScheme='green'  {...register("Is_Active",)}>
                                        </Checkbox>
                                        <FormErrorMessage>
                                        </FormErrorMessage>
                                    </FormControl>
                                ) : <></>
                            }
                        </Box>
                    </HStack>                         
                  
                
                  
                      
                  
                </Stack>
            </ModalComponent>
            {/* mapping part starts */}

           
        </ContainerComponent >
    )
}

export default MenuComponent