import axios from 'axios'
import * as CommonVariables from '../../Common/CommonVariables'
import { trackPromise } from 'react-promise-tracker';                       


//for bhk list
export const GetPriceMatrixMasterList = async () => {
    var result = await trackPromise(axios.get(CommonVariables.API_DOMAIN + `PriceMatrixMaster/GetPriceMatrixMasterList`, CommonVariables.API_HEADER)).then(response => response);
    return result;
}

//for detail by id
export const GetPriceMatrixDetailsById = async (id) => {
    var result = await trackPromise(axios.get(CommonVariables.API_DOMAIN + `PriceMatrixMaster/GetPriceMatrixListById?matrixid=${id}`, CommonVariables.API_HEADER)).then(response => response);
    return result;
}

//for saving new bhk detail
export const SavePriceMatrixDetails = async (data) => {
 
    var result = await trackPromise(axios.post(CommonVariables.API_DOMAIN + `PriceMatrixMaster/SavePriceMatrixDetails`,JSON.stringify(data), CommonVariables.API_HEADER)).then(response => response);
    return result;
}

//for updating existing bhk detail
export const UpdatePriceMatrixDetails = async (data) => {
    var result = await trackPromise(axios.post(CommonVariables.API_DOMAIN + `PriceMatrixMaster/UpdatePriceMatrixDetails`,JSON.stringify(data), CommonVariables.API_HEADER)).then(response => response);
    return result;
}


export const DeletePriceMatrixById = async (Id) => {
    //debugger;
    const response = await trackPromise(axios.post(CommonVariables.API_DOMAIN + `PriceMatrixMaster/DeletePriceMatrixById?matrixid=${Id}`, CommonVariables.API_HEADER))
        .then(response => response);
    return response;
}


