import React, { useEffect, useState, useRef } from 'react'
import { useForm } from 'react-hook-form'
import * as BrandService from '../../Services/Brand/BrandService'
import toast from 'react-hot-toast';
import ContainerComponent from '../Container/ContainerComponent';
import { VStack, FormControl,HStack, Tooltip,FormLabel, FormErrorMessage, Input, Checkbox, Modal, ModalOverlay, ModalContent, ModalBody, ModalHeader, ModalCloseButton, ModalFooter, useDisclosure, Flex, Stack, Heading, Table, ButtonGroup, Tfoot, Box, TableContainer, TableCaption, Spacer, Button, Thead, Tbody, Td, Tr, Th, Divider } from '@chakra-ui/react'
import { FaCopyright, FaPlus } from "react-icons/fa";
import ModalComponent from '../Modal/ModalComponent';
import { FiLayout } from 'react-icons/fi';
import { AddIcon, HamburgerIcon, EditIcon, DeleteIcon } from '@chakra-ui/icons';
import * as CommonVariables from '../../Common/CommonVariables';
import * as AttachmentService from "../../Services/Attachment/AttachmentService";
import FilePicker from "chakra-ui-file-picker";



function BrandComponent() {
    const { register, setValue, reset, setError, handleSubmit, formState: { errors } } = useForm({ mode: "onBlur" });
    const { isOpen: isAddOpen, onOpen: onAddOpen, onClose: onAddClose } = useDisclosure();
    const [brandlist, setBrandMasterList] = useState([]);
    const [selectedbrandid, setSelectedbrand] = useState(0);    
    const [page, setPage] = useState(1);
    const [brandImage, setBrandImage] = useState('');

    useEffect(() => {
          LoadBrandMasterList();
    }, [])

       
    const LoadBrandMasterList = () => {
        BrandService.GetBrandMasterList().then(data => {
            if (data.data.IsSuccess) {
                debugger;
                setBrandMasterList(data.data.Data);
            } else {
                toast.error("Somthing went wrong");
            }
        }).catch(error => {
            toast.error("Somthing went wrong");
        })
    }

 
    const AddBrand = () => {
        reset({});    
        reset();    
        setSelectedbrand(0);
        onAddOpen();
        setBrandImage('');

        
    }
    const validateBrand = (data) => {
        if( brandImage == ''){
            toast.error("Please upload one image.");
            return false;
          }
          return true;
    }
 
    const SaveBrandDetail = (value) => {
        debugger;
        if(validateBrand(value))
        {
       
        if (selectedbrandid <= 0 ) {
            value.ImageUrl =brandImage;
            BrandService.SaveBrandDetails(value).then(data => {
                if (data.data.IsSuccess) {
                    toast.success("Success.");
                    onAddClose();
                    LoadBrandMasterList();
                } else {
                    toast.error("Somthing went wrong.");
                }
            }).catch(error => {
                toast.error("Somthing went wrong.");
            })
        } else {
            value.Id = selectedbrandid;
            value.ImageUrl =brandImage;
            BrandService.UpdateBrandDetails(value).then(data => {
                if (data.data.IsSuccess) {
                    toast.success("Success");
                    onAddClose();
                    LoadBrandMasterList();
                } else {
                    toast.error("Somthing went wrong.");
                }
            }).catch(error => {
                toast.error("Somthing went wrong.");
            })
        }
    }
    else{
        return false;
    }
    }

    const EditBrandMaster = (id) => {
        setSelectedbrand(id);
        reset({});
        BrandService.GetBrandDetailsById(id).then(data => {
            if (data.data.IsSuccess) {
                reset(data.data.Data);
                setBrandImage(data.data.Data.ImageUrl);
                onAddOpen();
            } else {
                toast.error("Somthing went wrong");
            }
        }).catch(error => {
            toast.error("Somthing went wrong");
        })
    }

    const DeleteBrand = (id) => {
        debugger;
        var action = window.confirm('Do you want to delete Brand?');
        if (action == true) {
            BrandService.DeleteBrandById(id).then(data => {
                if (data.data.IsSuccess) {
                    console.log(data.data)
                    toast.success(data.data.ResponseMessage);
                    LoadBrandMasterList();
                }
            }).catch(error => {

            })
        }
    }

    const BrandFileUploadToS3 = (fileList) => {
        debugger;
        if (fileList != null && fileList.length > 0) {
          var formdata = new FormData();
          formdata.append("Myfile", fileList[0]);
    
          AttachmentService.FileUploadToS3(formdata)
            .then((response) => {
              if (response.data.IsSuccess) {
                toast.success(response.data.ResponseMessage);
                setBrandImage(response.data.Data);
              } else {
                setBrandImage("");
                toast.error(response.data.ResponseMessage);
              }
            })
            .catch((error) => {
              toast.error("Something went wrong!");
            });
        } else {
          toast.error("Please choose Image First!");
          setBrandImage("");
        }
      };

    return (




        <ContainerComponent Title="Brand Master List" Icon={<FiLayout />} ShowDownloadAction="false" ShowAction="true" BtnIcon={<FaPlus />} ButtonTitle="Brand" AddNewAction={AddBrand}>
            <TableContainer>
                <Table variant='simple'>
                    <Thead>
                        <Tr>
                        <Th>Id</Th>
                            <Th>Brand Name</Th>
                            <Th>Image Url</Th>  
                            <Th>Sequence No</Th> 
                            <Th>Is Active</Th>                         
                            <Th>Action</Th>
                         
                        </Tr>
                    </Thead>
                    <Tbody>
                        {brandlist.map((data, index) => {
                            return (<Tr key={index}>
                                <Td>{data.Id}</Td>
                                <Td>{data.Title}</Td>
                                <Td><a target='_blank' href={data.ImageUrl}><img height={"40px"} width={"40px"} src={data.ImageUrl}></img></a></Td>
                                <Td>{data.SequenceNo}</Td>
                            
                                <Td>
                                    <Checkbox colorScheme={data.Is_Active ? 'green' : 'red'} defaultChecked isReadOnly>
                                    </Checkbox>
                                </Td>
                                <Td>
                                <ButtonGroup variant={"outline"} spacing="1">
                <Tooltip label='Edit Brand' placement='auto-start'>
                    <Button colorScheme="blue" variant="solid" size={"xs"} onClick={() => EditBrandMaster(data.Id)} >
                        <EditIcon />
                    </Button >
                </Tooltip>
                <Tooltip label='Delete Brand' placement='auto-start'>
                    <Button colorScheme="red" variant="solid" size={"xs"} onClick={() => DeleteBrand(data.Id)}>
                        <DeleteIcon />
                    </Button>
                </Tooltip>
              
            </ButtonGroup >
                                </Td>
                            </Tr>)
                        })}
                    </Tbody>
                    {brandlist.length <= 0 ? (
                        <Tfoot>
                            <Tr colSpan="3">
                                <Td>
                                    No Data Available
                                </Td>
                            </Tr>
                        </Tfoot>
                    ) : <></>
                    }

                </Table>
            </TableContainer>
            {/* <Table
                size="sm"
                emptyData={{
                    text: "No Data Available."
                }}
                colorScheme="blue"
                totalRegisters={BHKList.length}
                page={page}
                onPageChange={(page) => setPage(page)}
                columns={tableColumns}
                data={tableData}
            /> */}
            <ModalComponent size="3xl" Title="Add/Update Brand" isOpen={isAddOpen} onOpen={onAddOpen} onClose={onAddClose} onSave={handleSubmit(SaveBrandDetail)} >
                <Stack spacing={4}>
                    <HStack>
                        <Box width={"full"}>
                            <FormControl isRequired isInvalid={errors.Title}>
                                <FormLabel>Brand Title</FormLabel>
                                <Input placeholder='Enter Brand Title' {...register("Title", { required: "Field is Mandatory" })} />
                                <FormErrorMessage>
                                    {errors.Title && errors.Title.message}
                                </FormErrorMessage>
                            </FormControl>
                        </Box>
                        </HStack>
                        <HStack>
                        <Box width={"full"}>
              <FormControl
                isRequired
                isInvalid={errors.PopUp_Image}>
                <FormLabel>Brand Image</FormLabel>

                <FilePicker
                  onFileChange={(fileList) => {
                    BrandFileUploadToS3(fileList);
                  }}
                  placeholder="Brand Image"
                  clearButtonLabel="Clear"
                  multipleFiles={false}
                  accept="application/png"
                  hideClearButton={false}
                />
                <FormErrorMessage>
                  {errors.brandImage && errors.brandImage.message}
                </FormErrorMessage>
              </FormControl>
           
            </Box>
            <Box width={"50%"}>
            <a href={brandImage} target="blank"><img src={brandImage} width='50px' height='50px' /></a>
            </Box>
                            </HStack>                    
                        

                       <HStack>
                       <Box width={"full"}>
                            <FormControl isRequired isInvalid={errors.SequenceNo}>
                                <FormLabel>SequenceNo</FormLabel>
                                <Input placeholder='Enter SequenceNo' {...register("SequenceNo", { required: "Field is Mandatory" })} />
                                <FormErrorMessage>
                                    {errors.SequenceNo && errors.SequenceNo.message}
                                </FormErrorMessage>
                            </FormControl>
                        </Box>
                    </HStack>                                 
                    <HStack>
                        <Box width={"full"}>
                            {
                                selectedbrandid > 0 ? (
                                    <FormControl>
                                        <FormLabel>Is Active</FormLabel>
                                        <Checkbox colorScheme='green'  {...register("Is_Active",)}>
                                        </Checkbox>
                                        <FormErrorMessage>
                                        </FormErrorMessage>
                                    </FormControl>
                                ) : <></>
                            }
                        </Box>
                    </HStack>
                
                   
                </Stack>
            </ModalComponent>
          
           
        </ContainerComponent >
    )
}

export default BrandComponent