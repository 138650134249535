import axios from 'axios'
import * as CommonVariables from '../../Common/CommonVariables'
import { trackPromise } from 'react-promise-tracker';                       


//for bhk list
export const GetVideoMasterList = async () => {
    var result = await trackPromise(axios.get(CommonVariables.API_DOMAIN + `VideoMaster/GetVideoMasterList`, CommonVariables.API_HEADER)).then(response => response);
    return result;
}

//for detail by id
export const GetVideoDetailsById = async (id) => {
    var result = await trackPromise(axios.get(CommonVariables.API_DOMAIN + `VideoMaster/GetVideoListById?videoid=${id}`, CommonVariables.API_HEADER)).then(response => response);
    return result;
}

//for saving new bhk detail
export const SaveVideoDetails = async (data) => {
 
    var result = await trackPromise(axios.post(CommonVariables.API_DOMAIN + `VideoMaster/SaveVideoDetails`,JSON.stringify(data), CommonVariables.API_HEADER)).then(response => response);
    return result;
}

//for updating existing bhk detail
export const UpdateVideoDetails = async (data) => {
    var result = await trackPromise(axios.post(CommonVariables.API_DOMAIN + `VideoMaster/UpdateVideoDetails`,JSON.stringify(data), CommonVariables.API_HEADER)).then(response => response);
    return result;
}


export const DeleteVideoById = async (Id) => {
    //debugger;
    const response = await trackPromise(axios.post(CommonVariables.API_DOMAIN + `VideoMaster/DeleteVideoById?videoid=${Id}`, CommonVariables.API_HEADER))
        .then(response => response);
    return response;
}


