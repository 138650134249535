import axios from 'axios'
import * as CommonVariables from '../../Common/CommonVariables'
import { trackPromise } from 'react-promise-tracker';                       


//for bhk list
export const GetBrandMasterList = async () => {
    var result = await trackPromise(axios.get(CommonVariables.API_DOMAIN + `BrandMaster/GetBrandList`, CommonVariables.API_HEADER)).then(response => response);
    return result;
}

//for detail by id
export const GetBrandDetailsById = async (id) => {
    var result = await trackPromise(axios.get(CommonVariables.API_DOMAIN + `BrandMaster/GetBrandListById?brandid=${id}`, CommonVariables.API_HEADER)).then(response => response);
    return result;
}

//for saving new bhk detail
export const SaveBrandDetails = async (data) => {
 
    var result = await trackPromise(axios.post(CommonVariables.API_DOMAIN + `BrandMaster/SaveBrandDetails`,JSON.stringify(data), CommonVariables.API_HEADER)).then(response => response);
    return result;
}

//for updating existing bhk detail
export const UpdateBrandDetails = async (data) => {
    var result = await trackPromise(axios.post(CommonVariables.API_DOMAIN + `BrandMaster/UpdateBrandDetails`,JSON.stringify(data), CommonVariables.API_HEADER)).then(response => response);
    return result;
}


export const DeleteBrandById = async (Id) => {
    //debugger;
    const response = await trackPromise(axios.post(CommonVariables.API_DOMAIN + `BrandMaster/DeleteBrandById?brandid=${Id}`, CommonVariables.API_HEADER))
        .then(response => response);
    return response;
}


