import React, { useEffect, useState, useRef } from 'react'
import { useForm } from 'react-hook-form'
import * as PriceMatrixService from '../../Services/PriceMatrix/PriceMatrixService'
import * as PinCodeService from '../../Services/ServicePinCode/ServicePincodeService'
import * as BHKService from '../../Services/BHK/BHKService'
import * as PlanService from '../../Services/ServicePlan/ServicePlanService'
import * as MasterService  from '../../Services/ServiceMaster/ServiceMasterService'

import toast from 'react-hot-toast';
import ContainerComponent from '../Container/ContainerComponent';
import { VStack, FormControl,HStack, Tooltip,FormLabel, FormErrorMessage, Input, Select,Checkbox, Modal, ModalOverlay, ModalContent, ModalBody, ModalHeader, ModalCloseButton, ModalFooter, useDisclosure, Flex, Stack, Heading, Table, ButtonGroup, Tfoot, Box, TableContainer, TableCaption, Spacer, Button, Thead, Tbody, Td, Tr, Th, Divider } from '@chakra-ui/react'
import { FaCopyright, FaPlus } from "react-icons/fa";
import ModalComponent from '../Modal/ModalComponent';
import { FiLayout } from 'react-icons/fi';
import { AddIcon, HamburgerIcon, EditIcon, DeleteIcon } from '@chakra-ui/icons';
import * as CommonVariables from '../../Common/CommonVariables';
import * as AttachmentService from "../../Services/Attachment/AttachmentService";
import FilePicker from "chakra-ui-file-picker";
import { Textarea } from 'evergreen-ui';



function PriceMatrixComponent() {
    const { register, setValue, reset, setError, handleSubmit, formState: { errors } } = useForm({ mode: "onBlur" });
    const { isOpen: isAddOpen, onOpen: onAddOpen, onClose: onAddClose } = useDisclosure();
    const [pricematrixlist, setPriceMatrixMasterList] = useState([]);
    const [selectedpricematrixid, SetSelectedPriceMatrix] = useState(0); 
    const [pincodelist, setPinCodeMasterList] = useState([]);
    const [bhklist, setBHKMasterList] = useState([]);
    const [planlist, setPlanMasterList] = useState([]);
    const [servicelist, setMasterList] = useState([]);
    
    const [page, setPage] = useState(1);
   
    useEffect(() => {
          LoadPriceMatrixMasterList();
          LoadPinCodeMasterList();
          LoadBHKMasterList();
          LoadPlanMasterList();
          LoadPServiceMasterList();
    }, [])

       
    const LoadPriceMatrixMasterList = () => {
        PriceMatrixService.GetPriceMatrixMasterList().then(data => {
            if (data.data.IsSuccess) {
                debugger;
                setPriceMatrixMasterList(data.data.Data);
            } else {
                toast.error("Somthing went wrong");
            }
        }).catch(error => {
            toast.error("Somthing went wrong");
        })
    }

    const LoadPinCodeMasterList = () => {
        PinCodeService.GetServicePinCodeMasterList().then(data => {
            if (data.data.IsSuccess) {
                debugger;
                setPinCodeMasterList(data.data.Data);
            } else {
                toast.error("Somthing went wrong");
            }
        }).catch(error => {
            toast.error("Somthing went wrong");
        })
    }
    const LoadBHKMasterList = () => {
        BHKService.GetBHKMasterList().then(data => {
            if (data.data.IsSuccess) {
                debugger;
                setBHKMasterList(data.data.Data);
            } else {
                toast.error("Somthing went wrong");
            }
        }).catch(error => {
            toast.error("Somthing went wrong");
        })
    }
    const LoadPlanMasterList = () => {
        PlanService.GetServicePlanMasterList().then(data => {
            if (data.data.IsSuccess) {
                debugger;
                setPlanMasterList(data.data.Data);
            } else {
                toast.error("Somthing went wrong");
            }
        }).catch(error => {
            toast.error("Somthing went wrong");
        })
    }
    const LoadPServiceMasterList = () => {
        MasterService.GetServiceMasterList().then(data => {
            if (data.data.IsSuccess) {
                debugger;
                setMasterList(data.data.Data);
            } else {
                toast.error("Somthing went wrong");
            }
        }).catch(error => {
            toast.error("Somthing went wrong");
        })
    }
    
 
    const Addmatrix = () => {
        reset({});    
        reset();    
        SetSelectedPriceMatrix(0);
        onAddOpen();
       

        
    }
   
 
    const SavePriceMatrixDetail = (value) => {
        debugger;
       
        if (selectedpricematrixid <= 0 ) {
           
            PriceMatrixService.SavePriceMatrixDetails(value).then(data => {
                if (data.data.IsSuccess) {
                    toast.success("Success.");
                    onAddClose();
                    LoadPriceMatrixMasterList();
                } else {
                    toast.error("Somthing went wrong.");
                }
            }).catch(error => {
                toast.error("Somthing went wrong.");
            })
        } else {
            value.Id = selectedpricematrixid;
          
            PriceMatrixService.UpdatePriceMatrixDetails(value).then(data => {
                if (data.data.IsSuccess) {
                    toast.success("Success");
                    onAddClose();
                    LoadPriceMatrixMasterList();
                } else {
                    toast.error("Somthing went wrong.");
                }
            }).catch(error => {
                toast.error("Somthing went wrong.");
            })
        }
    }
  
    

    const EditpriceMatrixMaster = (id) => {
        SetSelectedPriceMatrix(id);
        reset({});
        PriceMatrixService.GetPriceMatrixDetailsById(id).then(data => {
            if (data.data.IsSuccess) {
                reset(data.data.Data);
                onAddOpen();
            } else {
                toast.error("Somthing went wrong");
            }
        }).catch(error => {
            toast.error("Somthing went wrong");
        })
    }

    const DeletePriceMatrix = (id) => {
        debugger;
        var action = window.confirm('Do you want to delete Data?');
        if (action == true) {
            PriceMatrixService.DeletePriceMatrixById(id).then(data => {
                if (data.data.IsSuccess) {
                    console.log(data.data)
                    toast.success(data.data.ResponseMessage);
                    LoadPriceMatrixMasterList();
                }
            }).catch(error => {

            })
        }
    }

  

    return (

        <ContainerComponent Title="Price Matrix List" Icon={<FiLayout />} ShowDownloadAction="false" ShowAction="true" BtnIcon={<FaPlus />} ButtonTitle="Matrix" AddNewAction={Addmatrix}>
            <TableContainer>
                <Table variant='simple'>
                    <Thead>
                        <Tr>
                        <Th>Id</Th>
                            <Th>PinCode</Th>
                            <Th>BHK Name</Th> 
                            <Th>Service Plan Name</Th>    
                            <Th> Is Active</Th>                       
                            <Th>Action</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {pricematrixlist.map((data, index) => {
                            return (<Tr key={index}>
                                <Td>{data.Id}</Td>
                                <Td>{data.Pincode}</Td>
                                <Td>{data.No_Of_BHK}</Td>
                                <Td>{data.Service_Plan_Name}</Td>
                            
                                <Td>
                                    <Checkbox colorScheme={data.Is_Active ? 'green' : 'red'} defaultChecked isReadOnly>
                                    </Checkbox>
                                </Td>
                                <Td>
                                <ButtonGroup variant={"outline"} spacing="1">
                <Tooltip label='Edit Matrix' placement='auto-start'>
                    <Button colorScheme="blue" variant="solid" size={"xs"} onClick={() => EditpriceMatrixMaster(data.Id)} >
                        <EditIcon />
                    </Button >
                </Tooltip>
                <Tooltip label='Delete Matrix' placement='auto-start'>
                    <Button colorScheme="red" variant="solid" size={"xs"} onClick={() => DeletePriceMatrix(data.Id)}>
                        <DeleteIcon />
                    </Button>
                </Tooltip>
              
            </ButtonGroup >
                                </Td>
                            </Tr>)
                        })}
                    </Tbody>
                    {pricematrixlist.length <= 0 ? (
                        <Tfoot>
                            <Tr colSpan="3">
                                <Td>
                                    No Data Available
                                </Td>
                            </Tr>
                        </Tfoot>
                    ) : <></>
                    }

                </Table>
            </TableContainer>
          
            <ModalComponent size="2xl" Title="Add/Update Matrix" isOpen={isAddOpen} onOpen={onAddOpen} onClose={onAddClose} onSave={handleSubmit(SavePriceMatrixDetail)} >
                <Stack spacing={4}>
                    <HStack>
                    <Box width={"full"}>
                            <FormControl isRequired isInvalid={errors.PincodeId}>
                                <FormLabel>Select PinCode</FormLabel>
                                <Select className="form-control" {...register("PincodeId", { required: "Field is Mandatory" })} >
                                    <option value="">Select PinCode</option>
                                    {
                                        pincodelist.map((data, index) => {
                                            return (
                                                <option value={data.Id} key={index}>{data.Pincode}</option>
                                            )
                                        })
                                    }
                                </Select>
                                <FormErrorMessage>
                                    {errors.PincodeId && errors.PincodeId.message}
                                </FormErrorMessage>
                            </FormControl>
                        </Box>
                        <Box width={"full"}>
                            <FormControl isRequired isInvalid={errors.BHKId}>
                            <FormLabel>Select BHK Name</FormLabel>
                            <Select className="form-control" {...register("BHKId", { required: "Field is Mandatory" })} >
                                    <option value="">Select BHK Name</option>
                                    {
                                        bhklist.map((data, index) => {
                                            return (
                                                <option value={data.Id} key={index}>{data.No_Of_BHK}</option>
                                            )
                                        })
                                    }
                                </Select>
                                <FormErrorMessage>
                                    {errors.BHKId && errors.BHKId.message}
                                </FormErrorMessage>
                            </FormControl>
                        </Box>

                        <Box width={"full"}>
                            <FormControl isRequired isInvalid={errors.PlanId}>
                                <FormLabel>Select Service Plan</FormLabel>
                                  <Select className="form-control" {...register("PlanId", { required: "Field is Mandatory" })} >
                                    <option value="">Select Plan Name</option>
                                    {
                                        planlist.map((data, index) => {
                                            return (
                                                <option value={data.Id} key={index}>{data.Service_Plan_Name}</option>
                                            )
                                        })
                                    }
                                </Select>
                                <FormErrorMessage>
                                    {errors.PlanId && errors.PlanId.message}
                                </FormErrorMessage>
                            </FormControl>
                        </Box>
                        </HStack>
                        <HStack>
                        <Box width={"full"}>
                            <FormControl isRequired isInvalid={errors.Serviceid}>
                                <FormLabel>Select Service Name </FormLabel>
                                <Select className="form-control" {...register("Serviceid", { required: "Field is Mandatory" })} >
                                    <option value="">Select Name</option>
                                    {
                                        servicelist.map((data, index) => {
                                            return (
                                                <option value={data.Id} key={index}>{data.Service_Name}</option>
                                            )
                                        })
                                    }
                                </Select>
                              
                                <FormErrorMessage>
                                    {errors.Serviceid && errors.Serviceid.message}
                                </FormErrorMessage>
                            </FormControl>
                        </Box>

                        <Box width={"full"}>
                            <FormControl isRequired isInvalid={errors.Price}>
                                <FormLabel>Enter Price</FormLabel>
                                <Input placeholder='Enter Price' {...register("Price", { required: "Field is Mandatory" })} />
                                <FormErrorMessage>
                                    {errors.Price && errors.Price.message}
                                </FormErrorMessage>
                            </FormControl>
                        </Box>
                      </HStack>                     
                      <HStack>
                        <Box width={"full"}>
                            {
                                selectedpricematrixid > 0 ? (
                                    <FormControl>
                                        <FormLabel>Is Active</FormLabel>
                                        <Checkbox colorScheme='green'  {...register("Is_Active",)}>
                                        </Checkbox>
                                        <FormErrorMessage>
                                        </FormErrorMessage>
                                    </FormControl>
                                ) : <></>
                            }
                        </Box>
                    </HStack>
                                       
                   
                </Stack>
            </ModalComponent>
          
           
        </ContainerComponent >
    )
}

export default PriceMatrixComponent