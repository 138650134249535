import React, { useEffect, useState, useRef } from 'react'
import { useForm } from 'react-hook-form'
import * as OfferService from '../../Services/Offer/OfferService'
import toast from 'react-hot-toast';
import ContainerComponent from '../Container/ContainerComponent';
import { VStack, FormControl,HStack, Tooltip,FormLabel, FormErrorMessage, Input, Checkbox, Modal, ModalOverlay, ModalContent, ModalBody, ModalHeader, ModalCloseButton, ModalFooter, useDisclosure, Flex, Stack, Heading, Table, ButtonGroup, Tfoot, Box, TableContainer, TableCaption, Spacer, Button, Thead, Tbody, Td, Tr, Th, Divider } from '@chakra-ui/react'
import { FaCopyright, FaPlus } from "react-icons/fa";
import ModalComponent from '../Modal/ModalComponent';
import { FiLayout } from 'react-icons/fi';
import { AddIcon, HamburgerIcon, EditIcon, DeleteIcon } from '@chakra-ui/icons';
import * as CommonVariables from '../../Common/CommonVariables';
import * as AttachmentService from "../../Services/Attachment/AttachmentService";
import FilePicker from "chakra-ui-file-picker";



function OfferComponent() {
    const { register, setValue, reset, setError, handleSubmit, formState: { errors } } = useForm({ mode: "onBlur" });
    const { isOpen: isAddOpen, onOpen: onAddOpen, onClose: onAddClose } = useDisclosure();
    const [offerlist, setOfferMasterList] = useState([]);
    const [selectedofferid, SetSelectedoffer] = useState(0);    
    const [page, setPage] = useState(1);
    const [offerImage, setOfferImage] = useState('');

    useEffect(() => {
          LoadOfferMasterList();
    }, [])

       
    const LoadOfferMasterList = () => {
        OfferService.GetOfferMasterList().then(data => {
            if (data.data.IsSuccess) {
                debugger;
                setOfferMasterList(data.data.Data);
            } else {
                toast.error("Somthing went wrong");
            }
        }).catch(error => {
            toast.error("Somthing went wrong");
        })
    }

 
    const AddOffer = () => {
        reset({});      
        reset();  
        SetSelectedoffer(0);
        onAddOpen();
        setOfferImage('');

        
    }
    const validateOffer = (data) => {
        if( offerImage == ''){
            toast.error("Please upload one image.");
            return false;
          }
          return true;
    }
 
    const SaveOfferDetail = (value) => {
        debugger;
        if(validateOffer(value))
        {
       
        if (selectedofferid <= 0 ) {
            value.ImageUrl =offerImage;
            OfferService.SaveOfferDetails(value).then(data => {
                if (data.data.IsSuccess) {
                    toast.success("Success.");
                    onAddClose();
                    LoadOfferMasterList();
                } else {
                    toast.error("Somthing went wrong.");
                }
            }).catch(error => {
                toast.error("Somthing went wrong.");
            })
        } else {
            value.Id = selectedofferid;
            value.ImageUrl =offerImage;
            OfferService.UpdateOfferDetails(value).then(data => {
                if (data.data.IsSuccess) {
                    toast.success("Success");
                    onAddClose();
                    LoadOfferMasterList();
                } else {
                    toast.error("Somthing went wrong.");
                }
            }).catch(error => {
                toast.error("Somthing went wrong.");
            })
        }
    }
    else{
        return false;
    }
    }

    const EditOfferMaster = (id) => {
        SetSelectedoffer(id);
        reset({});
        OfferService.GetOfferDetailsById(id).then(data => {
            if (data.data.IsSuccess) {
                reset(data.data.Data);
                setOfferImage(data.data.Data.ImageUrl);
                onAddOpen();
            } else {
                toast.error("Somthing went wrong");
            }
        }).catch(error => {
            toast.error("Somthing went wrong");
        })
    }

    const DeleteOffer = (id) => {
        debugger;
        var action = window.confirm('Do you want to delete Offer?');
        if (action == true) {
            OfferService.DeleteOfferById(id).then(data => {
                if (data.data.IsSuccess) {
                    console.log(data.data)
                    toast.success(data.data.ResponseMessage);
                    LoadOfferMasterList();
                }
            }).catch(error => {

            })
        }
    }

    const OfferFileUploadToS3 = (fileList) => {
        debugger;
        if (fileList != null && fileList.length > 0) {
          var formdata = new FormData();
          formdata.append("Myfile", fileList[0]);
    
          AttachmentService.FileUploadToS3(formdata)
            .then((response) => {
              if (response.data.IsSuccess) {
                toast.success(response.data.ResponseMessage);
                setOfferImage(response.data.Data);
              } else {
                setOfferImage("");
                toast.error(response.data.ResponseMessage);
              }
            })
            .catch((error) => {
              toast.error("Something went wrong!");
            });
        } else {
          toast.error("Please choose Image First!");
          setOfferImage("");
        }
      };

    return (




        <ContainerComponent Title="Offer Master List" Icon={<FiLayout />} ShowDownloadAction="false" ShowAction="true" BtnIcon={<FaPlus />} ButtonTitle="Offer" AddNewAction={AddOffer}>
            <TableContainer>
                <Table variant='simple'>
                    <Thead>
                        <Tr>
                        <Th>Id</Th>
                            <Th>Offer Title</Th>
                            <Th>Image Url</Th>    
                            <Th>Is Active</Th>                       
                            <Th>Action</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {offerlist.map((data, index) => {
                            return (<Tr key={index}>
                                <Td>{data.Id}</Td>
                                <Td>{data.OfferTitle}</Td>
                                <Td><a target='_blank' href={data.ImageUrl}><img height={"40px"} width={"40px"} src={data.ImageUrl}></img></a></Td>
                        
                                <Td>
                                    <Checkbox colorScheme={data.Is_Active ? 'green' : 'red'} defaultChecked isReadOnly>
                                    </Checkbox>
                                </Td>
                                <Td>
                                <ButtonGroup variant={"outline"} spacing="1">
                <Tooltip label='Edit Offer' placement='auto-start'>
                    <Button colorScheme="blue" variant="solid" size={"xs"} onClick={() => EditOfferMaster(data.Id)} >
                        <EditIcon />
                    </Button >
                </Tooltip>
                <Tooltip label='Delete Offer' placement='auto-start'>
                    <Button colorScheme="red" variant="solid" size={"xs"} onClick={() => DeleteOffer(data.Id)}>
                        <DeleteIcon />
                    </Button>
                </Tooltip>
              
            </ButtonGroup >
                                </Td>
                            </Tr>)
                        })}
                    </Tbody>
                    {offerlist.length <= 0 ? (
                        <Tfoot>
                            <Tr colSpan="3">
                                <Td>
                                    No Data Available
                                </Td>
                            </Tr>
                        </Tfoot>
                    ) : <></>
                    }

                </Table>
            </TableContainer>
          
            <ModalComponent size="2xl" Title="Add/Update offer" isOpen={isAddOpen} onOpen={onAddOpen} onClose={onAddClose} onSave={handleSubmit(SaveOfferDetail)} >
                <Stack spacing={4}>
                    <HStack>
                        <Box width={"full"}>
                            <FormControl isRequired isInvalid={errors.OfferTitle}>
                                <FormLabel>Offer Title</FormLabel>
                                <Input placeholder='Enter Offer Title' {...register("OfferTitle", { required: "Field is Mandatory" })} />
                                <FormErrorMessage>
                                    {errors.OfferTitle && errors.OfferTitle.message}
                                </FormErrorMessage>
                            </FormControl>
                        </Box>
                        <Box width={"full"}>
                            <FormControl isRequired isInvalid={errors.SecondaryOfferTitle}>
                                <FormLabel>Secondary Offer Title</FormLabel>
                                <Input placeholder='Secondary Offer Title' {...register("SecondaryOfferTitle", { required: "Field is Mandatory" })} />
                                <FormErrorMessage>
                                    {errors.SecondaryOfferTitle && errors.SecondaryOfferTitle.message}
                                </FormErrorMessage>
                            </FormControl>
                        </Box>
                      </HStack>
                      <HStack>
                      <Box width={"full"}>
                            <FormControl isRequired isInvalid={errors.VoucherCode}>
                                <FormLabel>Voucher Code</FormLabel>
                                <Input placeholder='Enter Voucher Code' {...register("VoucherCode", { required: "Field is Mandatory" })} />
                                <FormErrorMessage>
                                    {errors.VoucherCode && errors.VoucherCode.message}
                                </FormErrorMessage>
                            </FormControl>
                        </Box>
                       
                        <Box width={"full"}>
                            <FormControl isRequired isInvalid={errors.PageLink}>
                                <FormLabel>Page Link</FormLabel>
                                <Input placeholder='Enter Page Link' {...register("PageLink", { required: "Field is Mandatory" })} />
                                <FormErrorMessage>
                                    {errors.PageLink && errors.PageLink.message}
                                </FormErrorMessage>
                            </FormControl>
                        </Box>
                        </HStack>
                        <HStack>
                        <Box width={"full"}>                          
                               
                               <FormControl>
                                   <FormLabel>Is Copy Enabled</FormLabel>
                                   <Checkbox  colorScheme='green'  {...register("IsCopyEnabled")}>
                                   </Checkbox>
                                   <FormErrorMessage>
                                   </FormErrorMessage>
                               </FormControl>
                        </Box>
                        <Box width={"full"}>                          
                               
                               <FormControl>
                                   <FormLabel>Is Redeem Enabled</FormLabel>
                                   <Checkbox  colorScheme='green'  {...register("IsRedeemEnabled")}>
                                   </Checkbox>
                                   <FormErrorMessage>
                                   </FormErrorMessage>
                               </FormControl>
                        </Box>
                       
                        <Box width={"full"}>                          
                               
                            <FormControl>
                                <FormLabel>Is AppLink</FormLabel>
                                <Checkbox  colorScheme='green'  {...register("IsAppLink")}>
                                </Checkbox>
                                <FormErrorMessage>
                                </FormErrorMessage>
                            </FormControl>
                     </Box>
                        </HStack>
                        <HStack>
                        <Box width={"full"}>                          
                               
                               <FormControl>
                                   <FormLabel>Is InAppBrowser Link</FormLabel>
                                   <Checkbox colorScheme='green'  {...register("IsInAppBrowserLink")}>
                                   </Checkbox>
                                   <FormErrorMessage>
                                   </FormErrorMessage>
                               </FormControl>
                        </Box>
                        <Box width={"full"}>                          
                               
                               <FormControl>
                                   <FormLabel>Is External AppBrowserLink</FormLabel>
                                   <Checkbox colorScheme='green'   {...register("IsExternalAppBrowserLink")}>
                                   </Checkbox>
                                   <FormErrorMessage>
                                   </FormErrorMessage>
                               </FormControl>
                        </Box>
                        <Box width={"full"}>
                            <FormControl isRequired isInvalid={errors.SequenceNo}>
                                <FormLabel>Enter Sequence No</FormLabel>
                                <Input placeholder='Enter Sequence No' {...register("SequenceNo", { required: "Field is Mandatory" })} />
                                <FormErrorMessage>
                                    {errors.SequenceNo && errors.SequenceNo.message}
                                </FormErrorMessage>
                            </FormControl>
                        </Box>
                        </HStack>

                      <HStack>
                        <Box width={"full"}>
              <FormControl
                isRequired
                isInvalid={errors.PopUp_Image}>
                <FormLabel>Offer Image Url</FormLabel>

                <FilePicker
                  onFileChange={(fileList) => {
                    OfferFileUploadToS3(fileList);
                  }}
                  placeholder="Offer Image"
                  clearButtonLabel="Clear"
                  multipleFiles={false}
                  accept="application/png"
                  hideClearButton={false}
                />
                <FormErrorMessage>
                  {errors.offerImage && errors.offerImage.message}
                </FormErrorMessage>
              </FormControl>
           
            </Box>
            <Box width={"50%"}>
            <a href={offerImage} target="blank"><img src={offerImage} width='50px' height='50px' /></a>
            </Box>

                       
                    </HStack>  
                    <HStack>
                        <Box width={"full"}>
                            {
                                selectedofferid > 0 ? (
                                    <FormControl>
                                        <FormLabel>Is Active</FormLabel>
                                        <Checkbox colorScheme='green'  {...register("Is_Active",)}>
                                        </Checkbox>
                                        <FormErrorMessage>
                                        </FormErrorMessage>
                                    </FormControl>
                                ) : <></>
                            }
                        </Box>
                    </HStack>                               
                  
                
                   
                </Stack>
            </ModalComponent>
          
           
        </ContainerComponent >
    )
}

export default OfferComponent