import React, { useEffect, useState, useRef } from 'react'
import { useForm } from 'react-hook-form'
import * as ServicePincodeService from '../../Services/ServicePinCode/ServicePincodeService'
import toast from 'react-hot-toast';
import ContainerComponent from '../Container/ContainerComponent';
import { VStack, FormControl,HStack, Tooltip,FormLabel, FormErrorMessage, Input, Checkbox, Modal, ModalOverlay, ModalContent, ModalBody, ModalHeader, ModalCloseButton, ModalFooter, useDisclosure, Flex, Stack, Heading, Table, ButtonGroup, Tfoot, Box, TableContainer, TableCaption, Spacer, Button, Thead, Tbody, Td, Tr, Th, Divider } from '@chakra-ui/react'
import { FaCopyright, FaPlus } from "react-icons/fa";
import ModalComponent from '../Modal/ModalComponent';
import { FiLayout } from 'react-icons/fi';
import { AddIcon, HamburgerIcon, EditIcon, DeleteIcon } from '@chakra-ui/icons';
import * as CommonVariables from '../../Common/CommonVariables';
import * as AttachmentService from "../../Services/Attachment/AttachmentService";
import FilePicker from "chakra-ui-file-picker";
import { Textarea } from 'evergreen-ui';



function ServicePinCodeComponent() {
    const { register, setValue, reset, setError, handleSubmit, formState: { errors } } = useForm({ mode: "onBlur" });
    const { isOpen: isAddOpen, onOpen: onAddOpen, onClose: onAddClose } = useDisclosure();
    const [servicepincodelist, setServicePinCodeMasterList] = useState([]);
    const [selectedservicepincodeid, SetSelectedServicePinCode] = useState(0);    
    const [page, setPage] = useState(1);
   
    useEffect(() => {
          LoadServicePinCodeMasterList();
    }, [])

       
    const LoadServicePinCodeMasterList = () => {
        ServicePincodeService.GetServicePinCodeMasterList().then(data => {
            if (data.data.IsSuccess) {
                debugger;
                setServicePinCodeMasterList(data.data.Data);
            } else {
                toast.error("Somthing went wrong");
            }
        }).catch(error => {
            toast.error("Somthing went wrong");
        })
    }

 
    const Addservicepincode= () => {
        reset({});  
        reset();      
        SetSelectedServicePinCode(0);
        onAddOpen();
       

        
    }
   
 
    const SaveServicePincodeDetail = (value) => {
        debugger;
       
        if (selectedservicepincodeid <= 0 ) {
           
            ServicePincodeService.SaveServicePinCodeDetails(value).then(data => {
                if (data.data.IsSuccess) {
                    toast.success("Success.");
                    onAddClose();
                    LoadServicePinCodeMasterList();
                } else {
                    toast.error("Somthing went wrong.");
                }
            }).catch(error => {
                toast.error("Somthing went wrong.");
            })
        } else {
            value.Id = selectedservicepincodeid;
          
            ServicePincodeService.UpdateServicePinCodeDetails(value).then(data => {
                if (data.data.IsSuccess) {
                    toast.success("Success");
                    onAddClose();
                    LoadServicePinCodeMasterList();
                } else {
                    toast.error("Somthing went wrong.");
                }
            }).catch(error => {
                toast.error("Somthing went wrong.");
            })
        }
    }
  
    

    const EditServicepinCodeMaster = (id) => {
        SetSelectedServicePinCode(id);
        reset({});
        ServicePincodeService.GetServicePinCodeDetailsById(id).then(data => {
            if (data.data.IsSuccess) {
                reset(data.data.Data);
                onAddOpen();
            } else {
                toast.error("Somthing went wrong");
            }
        }).catch(error => {
            toast.error("Somthing went wrong");
        })
    }

    const DeleteServicePinCode = (id) => {
        debugger;
        var action = window.confirm('Do you want to delete Data?');
        if (action == true) {
            ServicePincodeService.DeleteServicePinCodeById(id).then(data => {
                if (data.data.IsSuccess) {
                    console.log(data.data)
                    toast.success(data.data.ResponseMessage);
                    LoadServicePinCodeMasterList();
                }
            }).catch(error => {

            })
        }
    }

  

    return (

        <ContainerComponent Title="Service PinCode List" Icon={<FiLayout />} ShowDownloadAction="false" ShowAction="true" BtnIcon={<FaPlus />} ButtonTitle="PinCode" AddNewAction={Addservicepincode}>
            <TableContainer>
                <Table variant='simple'>
                    <Thead>
                        <Tr>
                        <Th>Id</Th>
                            <Th>PinCode</Th>
                            <Th>City</Th> 
                            <Th>State</Th>      
                            <Th>Is Active</Th>                          
                            <Th>Action</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {servicepincodelist.map((data, index) => {
                            return (<Tr key={index}>
                                <Td>{data.Id}</Td>
                                <Td>{data.Pincode}</Td>
                                <Td>{data.City}</Td>
                                <Td>{data.State}</Td>
                            
                                 <Td>
                                    <Checkbox colorScheme={data.Is_Active ? 'green' : 'red'} defaultChecked isReadOnly>
                                    </Checkbox>
                                </Td> 
                                <Td>
                                <ButtonGroup variant={"outline"} spacing="1">
                <Tooltip label='Edit Service PinCode' placement='auto-start'>
                    <Button colorScheme="blue" variant="solid" size={"xs"} onClick={() => EditServicepinCodeMaster(data.Id)} >
                        <EditIcon />
                    </Button >
                </Tooltip>
                <Tooltip label='Delete Service PinCode' placement='auto-start'>
                    <Button colorScheme="red" variant="solid" size={"xs"} onClick={() => DeleteServicePinCode(data.Id)}>
                        <DeleteIcon />
                    </Button>
                </Tooltip>
              
            </ButtonGroup >
                                </Td>
                            </Tr>)
                        })}
                    </Tbody>
                    {servicepincodelist.length <= 0 ? (
                        <Tfoot>
                            <Tr colSpan="3">
                                <Td>
                                    No Data Available
                                </Td>
                            </Tr>
                        </Tfoot>
                    ) : <></>
                    }

                </Table>
            </TableContainer>
          
            <ModalComponent size="2xl" Title="Add/Update Service PinCode" isOpen={isAddOpen} onOpen={onAddOpen} onClose={onAddClose} onSave={handleSubmit(SaveServicePincodeDetail)} >
                <Stack spacing={4}>
                    <HStack>
                        <Box width={"full"}>
                            <FormControl isRequired isInvalid={errors.Pincode}>
                                <FormLabel>Enter Pincode</FormLabel>
                                <Input placeholder='Enter Pincode' {...register("Pincode", { required: "Field is Mandatory" })} />
                                <FormErrorMessage>
                                    {errors.Pincode && errors.Pincode.message}
                                </FormErrorMessage>
                            </FormControl>
                        </Box>
                        <Box width={"full"}>
                            <FormControl isRequired isInvalid={errors.City}>
                                <FormLabel>Enter City</FormLabel>
                                <Input placeholder='Enter City' {...register("City", { required: "Field is Mandatory" })} />
                                <FormErrorMessage>
                                    {errors.City && errors.City.message}
                                </FormErrorMessage>
                            </FormControl>
                        </Box>
                        </HStack>
                        <HStack>
                        <Box width={"full"}>
                            <FormControl isRequired isInvalid={errors.State}>
                                <FormLabel>Enter State</FormLabel>
                                <Input placeholder='Enter State' {...register("State", { required: "Field is Mandatory" })} />
                                <FormErrorMessage>
                                    {errors.State && errors.State.message}
                                </FormErrorMessage>
                            </FormControl>
                        </Box>                       
                      </HStack>   
                      <HStack>
                        <Box width={"full"}>
                            {
                                selectedservicepincodeid > 0 ? (
                                    <FormControl>
                                        <FormLabel>Is Active</FormLabel>
                                        <Checkbox colorScheme='green'  {...register("Is_Active",)}>
                                        </Checkbox>
                                        <FormErrorMessage>
                                        </FormErrorMessage>
                                    </FormControl>
                                ) : <></>
                            }
                        </Box>
                    </HStack>              
                      </Stack>
            </ModalComponent>
          
           
        </ContainerComponent >
    )
}

export default ServicePinCodeComponent