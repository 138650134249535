import axios from "axios";
import * as CommonVariables from '../../Common/CommonVariables'
import { trackPromise } from 'react-promise-tracker';

export const FileUploadToS3 = async (image) => {
    const response = await trackPromise(axios.post(CommonVariables.API_DOMAIN + 'Attachment/UploadImage', image, CommonVariables.API_HEADER))
        .then(response => response);
    return response;
}


