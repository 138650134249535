import React, { useState, useRef } from 'react';
import { store } from '../../Store/store'
import { useNavigate } from 'react-router-dom';
import { IconButton, Avatar, Box, Flex, HStack, VStack, Icon, useColorModeValue, Text, useDisclosure, Menu, MenuButton, MenuDivider, MenuItem, MenuList, Stack, FormControl, FormLabel, Center, AvatarBadge, ButtonGroup, Button, Input } from '@chakra-ui/react';
import { SmallCloseIcon, Search2Icon } from '@chakra-ui/icons';
import { FiMenu, FiBell, FiChevronDown } from 'react-icons/fi';
import { thunk_reset_user } from '../../Action/action';
import { connect } from 'react-redux';
import ModalComponent from '../Modal/ModalComponent';
import * as UserService from '../../Services/User/UserService'
import * as moment from 'moment';
//import * as OrderListService from '../../Services/OrderList/OrderListService'
import toast, { Toaster } from 'react-hot-toast';
import ContainerComponent from '../Container/ContainerComponent';

function TopMenuComponent(props) {

    const { isOpen: isProfileOpen, onOpen: onProfileOpen, onClose: onProfileClose } = useDisclosure();
    const { isOpen: isResetPasswordOpen, onOpen: onResetPasswordOpen, onClose: onResetPasswordClose } = useDisclosure();
    const { isOpen: isSearchOrderAddOpen, onOpen: onSearchOrderAddOpen, onClose: onSearchOrderAddClose } = useDisclosure();
    const userInfo = store.getState();
    const navigate = useNavigate();
    //console.log(userInfo);

    const newPassword = useRef();
    const search = useRef();
    const [SearchOrderList, setSearchOrderList] = useState([]);

    const Logout = () => {
        props.dispatch(thunk_reset_user({}));
        navigate("/");
    }

    const Profile = () => {
        onProfileOpen();
    }
    const ResetPassword = () => {
        onResetPasswordOpen();
    }

    const ResetUserPassword = () => {
        UserService.ResetUserPassword(newPassword.current.value, userInfo.userData.Email_Id, userInfo.userData.User_Id).then(data => {
            if (data.data.IsSuccess) {
                onResetPasswordClose();
            } else {
                toast.error(data.data.Response);
            }
        }).catch(error => {
            toast.error("Somthing went wrong");
        })
    }

    // const SearchRecord = (e) => {
    //     e.preventDefault();
    //     if (search.current.value != "") {
    //         OrderListService.UniversalSearchByOrderNoMobileNo(search.current.value).then(data => {
    //             console.log(data)
    //             if (data.data.IsSuccess) {
    //                 console.log(data.data.Data);
    //                 onSearchOrderAddOpen()
    //                 setSearchOrderList(data.data.Data);
    //             } else {
    //                 toast.error("No Record Found")
    //                 setSearchOrderList([]);
    //             }
    //         }).catch(error => {
    //             toast.error("Somthing went wrong");
    //         })
    //     }
    //     else {
    //         toast.error("Please enter order/phoneno")
    //     }
    // }

    return (
        <Flex
            ml={{ base: 0, md: 52 }}
            px={{ base: 4, md: 4 }}
            height="20"
            alignItems="center"
            bg={useColorModeValue('white', 'gray.900')}
            borderBottomWidth="1px"
            borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
            justifyContent={{ base: 'space-between', md: 'flex-end' }}>
            <IconButton
                display={{ base: 'flex', md: 'none' }}
                onClick={() => props.onOpen()}
                variant="outline"
                aria-label="open menu"
                icon={<FiMenu />}
            />
            <Toaster
                position="top-center"
                reverseOrder={false}
            />
            {/* <HStack paddingBottom={4}>
                <Box width={"80%"} paddingTop={5}>
                    <FormControl >
                        <Input placeholder='Enter Order/PhoneNo' ref={search} />
                    </FormControl>
                </Box>
                <Box width={"30%"} paddingTop={5} paddingRight={5} border={1}>
                    <ButtonGroup variant={"outline"} spacing="1">
                        <Button variant="solid" colorScheme='red' right={2} onClick={(e) => SearchRecord(e)}>
                            <Search2Icon w={3} h={3} />
                        </Button>
                    </ButtonGroup>
                </Box>
            </HStack> */}

            <Text
                display={{ base: 'flex', md: 'none' }}
                fontSize="2xl"
                fontFamily="monospace"
                fontWeight="bold">
                Logo
            </Text>

            <HStack spacing={{ base: '0', md: '6' }}>
                <Flex alignItems={'center'}>

                    <Menu>
                        <MenuButton
                            py={2}
                            transition="all 0.3s"
                            _focus={{ boxShadow: 'none' }}>
                            <HStack>
                                <Avatar
                                    size={'sm'}
                                />
                                <VStack
                                    display={{ base: 'none', md: 'flex' }}
                                    alignItems="flex-start"
                                    spacing="1px"
                                    ml="2">
                                    <Text fontSize="sm">
                                        {userInfo.userData.First_Name} {userInfo.userData.Last_Name}
                                    </Text>
                                    <Text fontSize="xs" color="gray.600">
                                        {userInfo.userData.Role_Name}
                                    </Text>
                                </VStack>
                                <Box display={{ base: 'none', md: 'flex' }}>
                                    <FiChevronDown />
                                </Box>
                            </HStack>
                        </MenuButton>
                        <MenuList
                            bg={useColorModeValue('white', 'gray.900')}
                            borderColor={useColorModeValue('gray.200', 'gray.700')}>
                            <MenuItem onClick={() => Profile()}>Profile</MenuItem>
                            <MenuItem onClick={() => ResetPassword()}>Reset Password</MenuItem>
                            <MenuDivider />
                            <MenuItem onClick={() => Logout()}>Sign out</MenuItem>
                        </MenuList>
                    </Menu>
                </Flex>
            </HStack>
            <ModalComponent Title="User Profile" isOpen={isProfileOpen} onOpen={onProfileOpen} onClose={onProfileClose}>
                <Flex
                    align={'center'}
                    justify={'center'}
                >
                    <Stack spacing={2} w={'full'} maxW={'md'} bg={useColorModeValue('white', 'gray.700')} rounded={'xl'} p={2} my={1}>
                        <FormControl id="userName">
                            <Center>
                                <Stack direction={"row"} spacing={6}>
                                    <Avatar size="xl" src="https://bit.ly/sage-adebayo">

                                    </Avatar>
                                </Stack>
                            </Center>
                        </FormControl>
                        <FormControl id="userName" isRequired>
                            <FormLabel>User name</FormLabel>
                            <Input
                                placeholder="UserName"
                                _placeholder={{ color: 'gray.500' }}
                                type="text"
                                value={userInfo.userData.First_Name + " " + userInfo.userData.Last_Name}
                                readOnly={true}
                            />
                        </FormControl>
                        <FormControl id="email" isRequired>
                            <FormLabel>Email address</FormLabel>
                            <Input
                                placeholder="your-email@example.com"
                                _placeholder={{ color: 'gray.500' }}
                                type="email"
                                value={userInfo.userData.Email_Id}
                                readOnly={true}
                            />
                        </FormControl>
                        <FormControl id="password" isRequired>
                            <FormLabel>Mobile</FormLabel>
                            <Input
                                placeholder="Mobile"
                                _placeholder={{ color: 'gray.500' }}
                                type="text"
                                value={userInfo.userData.Mobile_No}
                                readOnly={true}
                            />
                        </FormControl>
                    </Stack>
                </Flex>
            </ModalComponent>
            <ModalComponent Title="Reset Password" isOpen={isResetPasswordOpen} onOpen={onResetPasswordOpen} onClose={onResetPasswordClose}>
                <Flex
                    align={'center'}
                    justify={'center'}
                >
                    <Stack
                        spacing={2}
                        w={'full'}
                        maxW={'md'}
                        bg={useColorModeValue('white', 'gray.700')}
                        rounded={'xl'}
                        p={2}
                        my={1}>


                        <FormControl id="email" isRequired>
                            <FormLabel>Email address</FormLabel>
                            <Input
                                placeholder="your-email@example.com"
                                _placeholder={{ color: 'gray.500' }}
                                type="email"
                                value={userInfo.userData.Email_Id}
                                readOnly={true}
                            />
                        </FormControl>
                        <FormControl>
                            <FormLabel>New Password</FormLabel>
                            <Input placeholder='New Password' ref={newPassword} />
                        </FormControl>

                        <Button onClick={() => ResetUserPassword()} marginTop={8}>
                            Reset Password
                        </Button>
                    </Stack>
                </Flex>
            </ModalComponent>

            

        </Flex >
    )
}




const mapStateToProps = state => {
    return {
        data: state
    }
}

export default connect(mapStateToProps)(TopMenuComponent);