import axios from 'axios'
import * as CommonVariables from '../../Common/CommonVariables'
import { trackPromise } from 'react-promise-tracker';                       

export const ResetUserPassword = async (newPassword,emailId,userId) => {
   
    const response = await trackPromise(axios.post(CommonVariables.API_DOMAIN + `UserMaster/UpdateUserPassword?userId=${userId}&emailId=${emailId}&newPassword=${newPassword}`, CommonVariables.API_HEADER))
        .then(response => response);
    return response;
}
//for usermaster list
export const GetUserMasterList = async () => {
    var result = await trackPromise(axios.get(CommonVariables.API_DOMAIN + `UserMaster/GetUserList`, CommonVariables.API_HEADER)).then(response => response);
    return result;
}

//for detail by id
export const GetUserDetailsById = async (id) => {
    var result = await trackPromise(axios.get(CommonVariables.API_DOMAIN + `UserMaster/GetUserById?userId=${id}`, CommonVariables.API_HEADER)).then(response => response);
    return result;
}

//for saving new user detail
export const SaveUserDetails = async (data) => {
 
    var result = await trackPromise(axios.post(CommonVariables.API_DOMAIN + `UserMaster/SaveUser`,JSON.stringify(data), CommonVariables.API_HEADER)).then(response => response);
    return result;
}

//for updating existing user detail
export const UpdateUserDetails = async (data) => {
    var result = await trackPromise(axios.post(CommonVariables.API_DOMAIN + `UserMaster/UpdateUser`,JSON.stringify(data), CommonVariables.API_HEADER)).then(response => response);
    return result;
}

export const GetUserRoleList = async () => {
    var result = await trackPromise(axios.get(CommonVariables.API_DOMAIN + `UserMaster/GetRoleList`, CommonVariables.API_HEADER)).then(response => response);
    return result;
}
export const ActivateDeactivateUser = async (Id,IsActive) => {
    //debugger;
    const response = await trackPromise(axios.post(CommonVariables.API_DOMAIN + `UserMaster/ActivateDeactivateUserDetail?id=${Id}&isActive=${IsActive}`, CommonVariables.API_HEADER))
        .then(response => response);
    return response;
}
export const GetUserDetailsByEmail = async (selectedemail) => {
    var result = await trackPromise(axios.get(CommonVariables.API_DOMAIN + `UserMaster/GetUserByEmail?email=${selectedemail}`, CommonVariables.API_HEADER)).then(response => response);
    return result;
}
export const ActivateDeactivateScMapping = async (Id,IsActive) => {
    //debugger;
    const response = await trackPromise(axios.post(CommonVariables.API_DOMAIN + `UserServiceCenterMapping/ActivateDeactivateSCUserMappingDetail?id=${Id}}`, CommonVariables.API_HEADER))
        .then(response => response);
    return response;
}
export const GetUserScMppingListById = async (selecteduser) => {
    var result = await trackPromise(axios.get(CommonVariables.API_DOMAIN + `UserServiceCenterMapping/GetSCUserMappingDetailListByUserId?userid=${selecteduser}`, CommonVariables.API_HEADER)).then(response => response);
    return result;
}

//for user service center mapping
export const SaveSCUserMappingDetail = async (data) => {
    var result = await trackPromise(axios.post(CommonVariables.API_DOMAIN + `UserServiceCenterMapping/SaveSCUserMappingDetail`,JSON.stringify(data), CommonVariables.API_HEADER)).then(response => response);
    return result;
}